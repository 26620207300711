import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"
import PropTypes from "prop-types"



const ProductionItemWorksTableViewCard = ({ items }) => {
  console.log(items);

  // const download = () => {
  //   window.print()
  // }
  return (
    <>
        <div className="d-flex" id="res-tab">
          <Table
            id="myTable"
            className="production-item-work-table table table-bordered mb-0 text-center"
          >

            <thead>
              <tr>
                <th id="1">Id</th>
                <th id="2">Work</th>
                <th id="3">Worker</th>
                <th id="4">Expected date</th>
                <th id="5">Finished date</th>
                <th id="6">Status</th>
                <th id="7">Note</th>
              </tr>
            </thead>


            <tbody>
              {items?.map((value, index) => (
                // <ProductionItemWorkViewCard item={value} key={index} />
                <tr key={index}>
                  <td id="1">
                    {value?.auto_id}
                  </td>
                  <td id="2">
                    {value?.work_type}
                  </td>
                  <td id="3">
                    {value?.worker_name}
                  </td>
                  <td id="4">
                    {value?.worker_name}
                  </td>
                  <td id="5">
                    {value?.worker_name}
                  </td>
                  <td id="6">
                    {value?.status}
                  </td>
                  <td id="7">
                    {value?.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
    </>
  )
}
ProductionItemWorksTableViewCard.propTypes = {
  items: PropTypes.array,
}
export default ProductionItemWorksTableViewCard
