import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { createProductionItemWork, getDealers, getProductionChoices, getWorkers, getWorkOrderChoices, updateProductionItemWork } from "store/actions"
import { useForm } from "react-hook-form"


const UpdateItemWorkModal = ({ show, onCloseClick,work }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedStatus, setSelectedStatus] = useState(work?.status)
  const { loading, productionChoices } = useSelector(
    state => ({
      loading: state.Orders.loading,
      productionChoices: state?.Production?.productionChoices,
      // workOrderChoices: state?.WorkOrders?.workOrderChoices,
    }))

  


  const productionItemStatusGroup = [
    {
      options: productionChoices?.production_status?.map((value, index) => ({
        key: index,
        label: value,
        value: value,
      })),
    },
  ]

  function handleProductionItemStatus(event) {
    setSelectedStatus(event.value)
    setValue('status', event.value);
  }


  function handleEnters(q) {
    // dispatch(getDealers(q, 1))

  }




  const { control, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      finished_date: work?.finished_date,
      status: work?.status,
      note: work?.note,
    }
  });

  const onSubmit = (data) => { 
    console.log("work /////");
    console.log(work);
      
    dispatch(updateProductionItemWork(data,work.id, history,onCloseClick,"/production-item-works"))
  };



  useEffect(() => {
    // dispatch(getDealers("", 1))
    dispatch(getProductionChoices("", 1))
    // dispatch(getWorkers("", 1))
    setSelectedStatus(work?.status)
 
  }, [work])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">

      <h4 className="mb-4 text-center font-size-18"> Update work item status</h4>
        <AvForm
          className="form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-3">
            <AvField
              name="finished_date"
              label="Finished date"
              placeholder="Finished date"
              className="form-control"
              type="date"
              value={work?.finished_date}
              onChange={(e) => setValue('finished_date', e.target.value)}

            />
          </div>
          <div className="mb-3">
            <AvField
              name="note"
              label="Note"
              placeholder="Note"
              className="form-control"
              type="textarea"
              value={work?.note}
              onChange={(e) => setValue('note', e.target.value)}

            />
          </div>
          <div className="mb-3">
            <Select
              onInputChange={handleEnters}
              value={selectedStatus}
              placeholder={selectedStatus}
              onChange={handleProductionItemStatus}
              options={productionItemStatusGroup}
              classNamePrefix="select2-selection"
              isLoading={false}
              required={"required"}
              name={"status"}
              // value={work?.status}

            />
          </div>

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block"
              type="submit"
            >
              Submit
            </button>
          </div>

        </AvForm>
      </ModalBody>
    </Modal>
  )
}

UpdateItemWorkModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  work: PropTypes.array
}

export default UpdateItemWorkModal
