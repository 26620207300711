import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//pages
import Dealers from "../pages/Dealers"
import DealerDetails from "../pages/Dealers/SingleView"
import CreateDealer from "../pages/Dealers/Crud/Create"
import UpdateDealer from "../pages/Dealers/Crud/Update"

import Supervisors from "../pages/Supervisor"
import SupervisorDetails from "../pages/Supervisor/SingleView"
import CreateSupervisor from "pages/Supervisor/Crud/Create"
import UpdateSupervisor from "pages/Supervisor/Crud/Update"
import DailyWork from "pages/Supervisor/DailyWork"

import StoreManager from "pages/Storemngr"
import StoreManagerDetails from "pages/Storemngr/SingleView"
import CreateStoreManager from "pages/Storemngr/Crud/Create"

import QualityChecker from "pages/Qualitychecker"
import QltCheckerDetails from "pages/Qualitychecker/SingleView"
import CreateQltChecker from "pages/Qualitychecker/Crud/Create"

import Product from "pages/Product"
import ProductDetails from "pages/Product/SingleView"
import UpdateProduct from "pages/Product/Crud/Update/Update"
import CreateProduct from "pages/Product/Crud/Create/Create"

import Orders from "pages/Orders"
import OrderDetails from "pages/Orders/SingleView"
import CreateOrder from "pages/Orders/Crud/Create/Create"

import WorkOrders from "pages/WorkOrders/Crud/List"
import WorkOrderDetails from "pages/WorkOrders/Crud/SingleView"
import CreateWorkOrder from "pages/WorkOrders/Crud/Create"

import ProductionItems from "pages/Production/ProductionItemsCrud/List"
import ProductionItemDetails from "pages/Production/ProductionItemsCrud/SingleView"
import ProductionItemWorkDetails from "pages/Production/ProductionItemWorksCrud/SingleView"
// import CreateWorkOrder from "pages/Production/Crud/Create"

import Store from "pages/Store"
import CreateStore from "pages/Store/Crud/Create"
import UpdateStore from "pages/Store/Crud/Update"

import Sheet from "pages/Sheet"
import CreateSheet from "pages/Sheet/Crud/Create"
import UpdateSheet from "pages/Sheet/Crud/Update"

import SheetOrders from "pages/SheetOrder"
import CreateSheetOrder from "pages/SheetOrder/Crud/Create"
import UpdateSheetOrder from "pages/SheetOrder/Crud/Update"
import GetTotalSheetOrderWeight from "pages/SheetOrder/Crud/TotalWeight"

import FinishedProduct from "pages/Orders/FinishedProduct"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"
import ProductionManager from "pages/ProductionMngr"
import ProductionManagerDetails from "pages/ProductionMngr/SingleView"
import CreateProductionManager from "pages/ProductionMngr/Crud/Create"

import Worker from "pages/Worker"
import WorkerDetails from "pages/Worker/SingleView"
import CreateWorker from "pages/Worker/Crud/Create"


import StoreSupply from "pages/Store/StoreSupply"
import ForgetPwd from "pages/Authentication/ForgetPwd"
import Schedule from "pages/Dashboard/SupervisorDashboard/Schedules"
import UpdateOrder from "pages/Orders/Crud/Create/Update"
import UpdateWorkOrder from "pages/WorkOrders/Crud/Update"
import CreateSheetUser from "pages/SheetUser/Crud/Create"
import SheetUser from "pages/SheetUser"
import SheetUserDetails from "pages/SheetUser/SingleView"
import FieldStaff from "pages/FieldStaff"
import FieldStaffDetails from "pages/FieldStaff/SingleView"
import FieldSite from "pages/FieldSite"
import FieldSiteDetails from "pages/FieldSite/SingleView"
import UpdateFieldSite from "pages/FieldSite/Crud/Update"
import VisitEntryDetails from "pages/FieldSite/VisitEntry/SingleView"
import CreateFieldSites from "pages/FieldSite/Crud/Create"
import Create from "pages/FieldSite/VisitEntry/Crud/Create"
import Update from "pages/FieldSite/VisitEntry/Crud/Update"
import AllVisitEntries from "pages/VisitEntry"
import AllFields from "pages/Fields"
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy"
import ProductionItemWorks from "pages/Production/ProductionItemWorksCrud/List"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for admin only
const AdminProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  //components
  { path: "/dealers", component: Dealers },
  { path: "/dealers/:id", component: DealerDetails },
  { path: "/dealer/create", component: CreateDealer },
  { path: "/dealer/update/:id", component: UpdateDealer },

  { path: "/supervisors", component: Supervisors },
  { path: "/supervisors/:id", component: SupervisorDetails },
  { path: "/supervisor/create", component: CreateSupervisor },
  { path: "/supervisor/update/:id", component: UpdateSupervisor },

  { path: "/supervisor/dailywork", component: DailyWork },

  { path: "/storemanagers", component: StoreManager },
  { path: "/storemanagers/:id", component: StoreManagerDetails },
  { path: "/storemanager/create", component: CreateStoreManager },

  { path: "/sheetusers", component: SheetUser },
  { path: "/sheetusers/:id", component: SheetUserDetails },
  { path: "/sheetuser/create", component: CreateSheetUser },

  { path: "/productionmanagers", component: ProductionManager },
  { path: "/productionmanagers/:id", component: ProductionManagerDetails },
  { path: "/productionmanager/create", component: CreateProductionManager },

  { path: "/workers", component: Worker },
  { path: "/workers/:id", component: WorkerDetails },
  { path: "/worker/create", component: CreateWorker },


  { path: "/qualitycheckers", component: QualityChecker },
  { path: "/qualitycheckers/:id", component: QltCheckerDetails },
  { path: "/qualitychecker/create", component: CreateQltChecker },

  { path: "/stores", component: Store },
  { path: "/store/create", component: CreateStore },
  { path: "/store/update/:id", component: UpdateStore },
  { path: "/store", component: StoreSupply },

  { path: "/sheets", component: Sheet },
  { path: "/sheet/create", component: CreateSheet },
  { path: "/sheet/update/:id", component: UpdateSheet },

  { path: "/sheets/sheetorders", component: SheetOrders },
  { path: "/sheets/sheetorders/create", component: CreateSheetOrder },
  { path: "/sheets/sheetorders/update/:id", component: UpdateSheetOrder },
  {
    path: "/sheets/sheetorders/total-weight",
    component: GetTotalSheetOrderWeight,
  },

  // { path: "/sheet", component: StoreSupply },

  { path: "/products", component: Product },
  { path: "/products/:id", component: ProductDetails },
  { path: "/product/create", component: CreateProduct },
  { path: "/product/update/:id", component: UpdateProduct },

  { path: "/product/finished", component: FinishedProduct },

  { path: "/orders", component: Orders },
  { path: "/orders/:id", component: OrderDetails },
  { path: "/order/create", component: CreateOrder },
  { path: "/order/update/:id", component: UpdateOrder },


  { path: "/workorders", component: WorkOrders },
  { path: "/workorders/:id", component: WorkOrderDetails },
  { path: "/workorder/create", component: CreateWorkOrder },
  { path: "/workorders/update/:id", component: UpdateWorkOrder },
  
  { path: "/production-items", component: ProductionItems },
  { path: "/production-items/:id", component: ProductionItemDetails },
  // { path: "/workorder/create", component: CreateWorkOrder },
  // { path: "/workorder/update/:id", component: UpdateWorkOrder },

  { path: "/production-item-works", component: ProductionItemWorks },
  { path: "/production-item-works/:id", component: ProductionItemWorkDetails },
  // { path: "/workorder/create", component: CreateWorkOrder },
  // { path: "/workorder/update/:id", component: UpdateWorkOrder },



  { path: "/supervisor/schedules", component: Schedule },

  { path: "/fields", component: AllFields },

  { path: "/fieldstaff", component: FieldStaff },
  { path: "/fieldstaff/:id", component: FieldStaffDetails },
  // { path: "/fieldstaff/create", component: CreateProduct },
  // { path: "/fieldstaff/update/:id", component: UpdateProduct },

  { path: "/fieldsite", component: FieldSite },
  { path: "/fieldsite/:id", component: FieldSiteDetails },
  { path: "/field-site/create", component: CreateFieldSites },
  { path: "/field-site/update/:id", component: UpdateFieldSite },

  { path: "/visit-entry/:id", component: VisitEntryDetails },
  { path: "/visitEntry/create", component: Create },
  { path: "/visitEntry/update/:id", component: Update },
  { path: "/visit-entry", component: AllVisitEntries },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

//for productionManager only
const productionManagerRoutes = AdminProtectedRoutes?.filter(
  route =>
    route.path !== "/productionmanagers" &&
    route.path !== "/productionmanagers/:id" &&
    route.path !== "/productionmanager/create"
)

// for supervisor only
const supervisorRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  //components
  { path: "/products", component: Product },
  { path: "/products/:id", component: ProductDetails },

  { path: "/product/finished", component: FinishedProduct },

  { path: "/supervisor/schedules", component: Schedule },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for storemanager only
const storemanagerRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  //components
  { path: "/products", component: Product },
  { path: "/products/:id", component: ProductDetails },

  { path: "/product/finished", component: FinishedProduct },

  { path: "/store", component: StoreSupply },

  { path: "/stores", component: Store },
  { path: "/store/create", component: CreateStore },
  { path: "/store/update/:id", component: UpdateStore },

  { path: "/sheets", component: Sheet },
  { path: "/sheet/create", component: CreateSheet },
  { path: "/sheet/update/:id", component: UpdateSheet },

  { path: "/sheets/sheetorders", component: SheetOrders },
  { path: "/sheets/sheetorders/create", component: CreateSheetOrder },
  { path: "/sheets/sheetorders/update/:id", component: UpdateSheetOrder },
  {
    path: "/sheets/sheetorders/total-weight",
    component: GetTotalSheetOrderWeight,
  },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const sheetuserRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/sheets", component: Sheet },
  // { path: "/sheet/create", component: CreateSheet },
  // { path: "/sheet/update/:id", component: UpdateSheet },

  { path: "/sheets/sheetorders", component: SheetOrders },
  { path: "/sheets/sheetorders/create", component: CreateSheetOrder },
  { path: "/sheets/sheetorders/update/:id", component: UpdateSheetOrder },
  {
    path: "/sheets/sheetorders/total-weight",
    component: GetTotalSheetOrderWeight,
  },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for worker only
const workerRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  //components
  { path: "/production-items", component: ProductionItems },
  { path: "/production-items/:id", component: ProductionItemDetails },

  { path: "/production-item-works", component: ProductionItemWorks },
  { path: "/production-item-works/:id", component: ProductionItemWorkDetails },

  { path: "/workorders", component: WorkOrders },
  { path: "/workorders/:id", component: WorkOrderDetails },
  { path: "/workorders/update/:id", component: UpdateWorkOrder },

  // { path: "/workorder/create", component: CreateWorkOrder },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]


//for dealer only
const dealerRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  //components
  { path: "/products", component: Product },
  { path: "/products/:id", component: ProductDetails },

  { path: "/orders", component: Orders },
  { path: "/orders/:id", component: OrderDetails },
  { path: "/order/create", component: CreateOrder },

  { path: "/workorders", component: WorkOrders },
  { path: "/workorders/:id", component: WorkOrderDetails },
  { path: "/workorders/update/:id", component: UpdateWorkOrder },

  // { path: "/workorder/create", component: CreateWorkOrder },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for qualitychecker only
const qualitycheckerRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  //components
  { path: "/products", component: Product },
  { path: "/products/:id", component: ProductDetails },

  { path: "/product/finished", component: FinishedProduct },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },

  { path: "/privacy-policy", component: PrivacyPolicy },
]

export {
  publicRoutes,
  AdminProtectedRoutes,
  dealerRoutes,
  workerRoutes,
  commonRoute,
  productionManagerRoutes,
  supervisorRoutes,
  storemanagerRoutes,
  qualitycheckerRoutes,
  sheetuserRoutes,
}
