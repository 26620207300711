import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"

import logo from "../../../assets/images/logo/LOHA.png"
import isoBadge from "../../../assets/images/iso-badge.png"
import { getWorkOrderChoices, getWorkOrderDetail, updateWorkOrder } from "store/actions"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { getDealers } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const Update = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const Role = localStorage.getItem("role")
  const { orderDetail, loading, orderitemLoading, dealers, workOrderChoices } = useSelector(
    state => ({
      orderDetail: state.WorkOrders.workOrderDetail,
      orderitem: state.Orders.orderDetail.orderitem,
      loading: state.Orders.loading,
      orderitemLoading: state.Orders.orderitemLoading,
      dealers: state?.Dealers?.dealers,
      workOrderChoices: state?.WorkOrders?.workOrderChoices,
    })
  )


  const [selectedDealer, setSelectedDealer] = useState({ "label": "Select dealer", "value": "10a4ea9c-4c98-4708-a704-1bb1f1891089" })
  const [selectedImages, setSelectedImages] = useState([])

  const download = () => {
    window.print()
  }

  // function userExists(arr, name) {
  //   const isExist = arr.some(function (el) {
  //     return el.addon_name === name
  //   })p
  //   return { isExist: isExist, data: arr.filter(i => i.addon_name === name)[0] }
  // }

  // const handleOrderItemAddon = arr => {
  //   const modifiedArr = arr?.filter(
  //     i => i?.addon_name !== "Manchary" && i?.addon_name !== "Panel"
  //   )
  //   return modifiedArr
  // }

  // const handleAddonTd = item => {
  //   if (item?.addon_name === "Manchary") {
  //     return <td>9</td>
  //   }
  // }

  useEffect(() => {
    dispatch(getDealers("", 1))
    dispatch(getWorkOrderChoices("", 1))
    dispatch(getWorkOrderDetail(params.id))

  }, [])

  // useEffect(() => {
  //   console.log(workOrderChoices);
  //   console.log(control);
  //   console.log(fields);


  // }, [workOrderChoices])

  useEffect(() => {
    setSelectedDealer({ "label": orderDetail?.dealer_name, "value": orderDetail?.dealer })
    setValue('mobile', orderDetail?.mobile);
    setValue('order_date', orderDetail?.order_date);
    setValue('customer_name', orderDetail?.customer_name);
    setValue('delivery_date', orderDetail?.delivery_date);
    setValue('delivery_site', orderDetail?.delivery_site);
    setValue('billing_address', orderDetail?.billing_address);
    setValue('gst_number', orderDetail?.gst_number);
    setValue('total_cost', orderDetail?.total_cost);
    setValue('work_order_items', orderDetail?.work_order_items);
    setValue('work_order_images', orderDetail?.work_order_images);
    // setSelectedImages(orderDetail?.work_order_images ? orderDetail.work_order_images : []);
    console.log(orderDetail);


    // convertImagesToBase64();
    // setSelectedImages()
    handleRawImages(orderDetail?.work_order_images ? orderDetail.work_order_images : [])

  }, [orderDetail])




  useEffect(() => {
    setValue('work_order_images', selectedImages);
    console.log(selectedImages);
    
  }, [selectedImages])




  function handleEnters(q) {
    dispatch(getDealers(q, 1))

  }
  function handleDealerSelect(event) {
    setSelectedDealer({ "label": event.label, "value": event.value })
    setValue('mobile', event.mobile);
  }
  const dealerOptionGroup = [
    {
      options: dealers?.results?.map((result, index) => ({
        key: index,
        label: `${result?.account.username}`,
        value: result?.id,
        mobile: result?.account?.phone
      })),
    },
  ]



  const handleImages = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Converts file to base64 string
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    });

    Promise.all(promises)
      .then(base64Files => {
        console.log("one");
        
        setSelectedImages((prevImages) => [...prevImages, { image: base64Files[0] }]);
        
      })
      .catch((error) => {
        console.error('Error converting files to base64:', error);
      });
  };

  const handleRawImages = async (rawImages) => {
    const promises = rawImages.map(async (file) => {
      try {
        const response = await fetch(file.image); // Fetch the image from the URL
        const blob = await response.blob(); // Get the image as a Blob
        const base64Image = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Convert Blob to Base64 string
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        return {
          ...file,
          image: base64Image // Replace image URL with Base64 string
        };
      } catch (error) {
        console.error('Error converting file to base64:', error);
        return file; // Return the original file object in case of an error
      }
    });

    // try {
    //   const base64Files = await Promise.all(promises);
    //   console.log("Base64 Files:", base64Files);
    //   console.log("two");

    //   setSelectedImages((prevImages) => [...prevImages, ...base64Files]);
    // } catch (error) {
    //   console.error('Error processing images:', error);
    // }

    try {
      const base64Files = await Promise.all(promises);
    
      console.log("Base64 Files:", base64Files);
    
      setSelectedImages((prevImages) => {
        // Map new files to the desired object structure
        const newImages = base64Files
    
        // Combine previous images and new images
        const combinedImages = [...prevImages, ...newImages];
    
        // Deduplicate based on the `image` property
        const uniqueImages = combinedImages.reduce((acc, current) => {
          const exists = acc.some((img) => img.image === current.image);
          return exists ? acc : [...acc, current];
        }, []);
    
        return uniqueImages;
      });
    } catch (error) {
      console.error("Error processing images:", error);
    }

  };



  // function handleImages(event) {
  //   console.log(selectedImages);
  //   setSelectedImages((prevImages) => [...prevImages, event.target.files[0]]);
  //   console.log(event);
  //   console.log(selectedImages);
  //   // setRawData({
  //   //   ...rawData,
  //   //   orderitem: {
  //   //     ["product"]: event.value,
  //   //     ["productName"]: event.label,
  //   //   },
  //   // })
  //   // dispatch(getAddons(event?.value))
  // }

  const handleRemoveImage = (index) => {
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };

  const current = new Date();

  const { control, handleSubmit, register, setValue } = useForm({
    // values: {
    //   work_order_images: selectedImages,
    // },

    defaultValues: {
      title: 'test title',
      dealer: selectedDealer?.value,
      order_date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,
      customer_name: '',
      delivery_date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,
      delivery_site: '',
      billing_address: '',
      gst_number: '',
      total_cost: 0,
      // work_order_items: [{
      //   channel_size: '',
      //   channel_guage: '',
      //   particulars: '',
      //   width: '',
      //   height: '',
      //   grill_type: '',
      //   manchary: false,
      //   louvers: false,
      //   panel: 'normal',
      //   design: 'normal',
      //   divider: 0,
      //   open_side: '',
      //   doorsill: false,
      //   quantity: 0,
      //   hinges: 0,
      //   stopper: 0,
      //   gate_hook: 0,
      //   lock: 0,
      //   item_cost: 0,
      //   item_price: 0,
      // },],
      // work_order_images: selectedImages
    }
  });

  // useEffect(() => {
  //   setValue('work_order_images', selectedImages); // Update the work_order_images field in the form
  // }, [selectedImages, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'work_order_items', // Name must match the array field in useForm
  });


  const onSubmit = (data) => {
    data['dealer'] = selectedDealer?.value;
    dispatch(updateWorkOrder(data, params.id, history))
    console.log("data");
    console.log(data);

  };

  // selectedImages.map((image, index) => {
  //   console.log("image");
  //   console.log(image);
  // })



  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className="page-content print_bg_white">
          <div className="d-flex align-items-center justify-content-end pt-5 print_hide">

            <div
              type="button"
              className="btn btn-outline-info d-flex display-none"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={download}
            >
              PDF
              <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-4 px-2">
            <div className="w-33"><img
              src={isoBadge}
              alt="image"
              // className="display-block d-none"
              height={80}
              width={80}
            /></div>
            <div
              className="d-flex align-items-center w-33"
              style={{ flexDirection: "column" }}
            >
              <img
                src={logo}
                alt="image"
                // className="display-block d-none"
                height={50}
                width={200}
              />
              <p className="pt-1 mb-0 pb-0">Vengara, Malappuram</p>
              {/* <p className="m-0 p-0">DT. Kerala(32)-676304</p> */}
              <a href="tel:+919288022780">PH: +91 9288 022 780</a>
              <a href="mailto:lohasteelsales@gmail.com">
                Lohasteelsales@gmail.com
              </a>
            </div>
            <div className="w-33 d-flex align-items-center justify-content-end order-number">
              <div className="w-fit">
                <p className="d-flex order_border mb-0 "> Order No : </p>
                <input placeholder="Order No" value={orderDetail?.auto_id} />
              </div>
            </div>
          </div>


          <AvForm className="workorder-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between align-items-stretch">
              <div className="table-responsive left-box">
                <Table className="table address_table table-bordered mb-0 w-100">
                  <tbody>
                    <tr>
                      <th>DEALER</th>
                      <td>
                        <Select
                          onInputChange={handleEnters}
                          value={selectedDealer}
                          placeholder={selectedDealer}
                          onChange={handleDealerSelect}
                          options={dealerOptionGroup}
                          classNamePrefix="select2-selection"
                          isLoading={false}
                          required={"required"}
                          name={"dealer"}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">MOB</th>
                      <td>
                        <input
                          {...register('mobile')}
                          placeholder="mobile"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">ORDER DATE</th>
                      <td>
                        <input type="date"
                          {...register('order_date')}
                          placeholder="order_date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CUSTOMER NAME</th>
                      <td>
                        <input
                          {...register('customer_name')}
                          placeholder="customer_name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY DATE</th>
                      <td>

                        {/* {moment(orderDetail?.end_date)?.format("DD-MM-yyyy")} */}

                        <input type="date"
                          {...register('delivery_date')}
                          placeholder="delivery_date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY SITE</th>
                      <td>
                        <input
                          {...register('delivery_site')}
                          placeholder="delivery_site"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">BILLING ADDRESS</th>
                      <td>
                        <input
                          {...register('billing_address')}
                          placeholder="billing_address"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">GST NUMBER</th>
                      <td>
                        <input
                          {...register('gst_number')}
                          placeholder="gst_number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

              </div>
              <div className="table-responsive w-100 align-items-stretch">
                <Table className="table table-bordered mb-0 w-100">
                  <thead>
                    <tr>
                      <th className="text-center p-0">Drawings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedImages?.length > 0 &&
                          selectedImages.map((image, index) => (
                            <div className="image-container" key={index} style={{ margin: '10px' }}>
                              <img
                                src={image.image}
                                alt={`Selected file ${index + 1}`}
                                width="100"
                                onLoad={() => image} // Revoke object URL to release memory
                              />
                              <span className="print_hide"
                                onClick={() => handleRemoveImage(index)}
                                disabled
                              >
                                X
                              </span>
                            </div>
                          ))}

                      </td>
                    </tr>
                    <tr className="print_hide">
                      <td>
                        <AvField
                          className=""
                          type={"file"}
                          name={"work_order_image"}
                          errorMessage="Invalid Input"
                          placeholder={"Drawings"}
                          onChange={e =>
                            handleImages(
                              e
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="row-box">
              <div className="d-flex overflow-x-scroll print_scroll_hide" id="res-tab">
                <Table
                  id="myTable"
                  className="table order_item_table table-bordered mb-0 text-center"
                >
                  <thead>
                    <tr>
                      <th id="1">Sl No:</th>
                      <th id="2">Channel
                        Size</th>
                      <th id="2.5">Gauge</th>
                      <th id="3">Particulars</th>
                      <th id="4">Width</th>
                      <th id="5">Height</th>
                      <th id="6">Grill Type</th>
                      <th id="7">Manchary</th>
                      <th id="7">Louvers</th>
                      <th id="8">Panel</th>
                      <th id="9">Design</th>
                      <th id="13">Divider</th>
                      <th id="15">OpenSide</th>
                      <th id="7">Doorsill</th>
                      <th id="17">Quantity</th>
                      <th id="17">Hinges</th>
                      {/* <th id="17">Stopper</th>
                      <th id="17">Gate
                        Hook</th>
                      <th id="17">Lock</th> */}
                      <th id="18" className="print_hide"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(fields, (field, index) => (
                      <tr className="workorderitem" id={field.id} key={index}>
                        <td id="1">{index + 1}</td>
                        <td id="2" className="text-red">
                          <Controller
                            name={`work_order_items[${index}].channel_size`}
                            control={control}
                            defaultValue={field.channel_size || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.channel_size.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="2.5">
                          <Controller
                            name={`work_order_items[${index}].channel_guage`}
                            control={control}
                            defaultValue={field.channel_guage || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "70px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.channel_guage.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="3">
                          <Controller
                            name={`work_order_items[${index}].particulars`}
                            control={control}
                            defaultValue={field.particulars}
                            render={({ field }) =>
                              <input style={{ width: "120px" }} {...field} />}
                          />
                        </td>
                        <td id="4">
                          <Controller
                            name={`work_order_items[${index}].width`}
                            control={control}
                            defaultValue={field.width}
                            render={({ field }) =>
                              <input style={{ width: "60px" }} {...field} />}
                          />
                        </td>
                        <td id="5">
                          <Controller
                            name={`work_order_items[${index}].height`}
                            control={control}
                            defaultValue={field.height}
                            render={({ field }) =>
                              <input style={{ width: "60px" }} {...field} />}
                          />
                        </td>
                        <td id="6">
                          <Controller
                            name={`work_order_items[${index}].grill_type`}
                            control={control}
                            defaultValue={field.grill_type || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.grill_type.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="7">
                          <Controller
                            name={`work_order_items[${index}].manchary`}
                            control={control}
                            defaultValue={field.manchary || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} checked={field.value} />
                            )}
                          />


                        </td>
                        <td id="8">
                          <Controller
                            name={`work_order_items[${index}].louvers`}
                            control={control}
                            defaultValue={field.louvers || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} checked={field.value} />
                            )}
                          />
                        </td>
                        <td id="9">
                          <Controller
                            name={`work_order_items[${index}].panel`}
                            control={control}
                            defaultValue={field.panel || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.panel_type.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="10">
                          <Controller
                            name={`work_order_items[${index}].design`}
                            control={control}
                            defaultValue={field.design || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.design_type.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="11">
                          <Controller
                            name={`work_order_items[${index}].divider`}
                            control={control}
                            defaultValue={field.divider}
                            render={({ field }) =>
                              <input type="number" style={{ width: "42px" }} {...field} />}
                          />
                        </td>
                        <td id="12">
                          <Controller
                            name={`work_order_items[${index}].open_side`}
                            control={control}
                            defaultValue={field.open_side || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.open_side.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="13">
                          <Controller
                            name={`work_order_items[${index}].doorsill`}
                            control={control}
                            defaultValue={field.doorsill || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} checked={field.value} />
                            )}
                          />
                        </td>
                        <td id="14">
                          <Controller
                            name={`work_order_items[${index}].quantity`}
                            control={control}
                            defaultValue={field.quantity}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        <td id="15">
                          <Controller
                            name={`work_order_items[${index}].hinges`}
                            control={control}
                            defaultValue={field.hinges}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        {/* <td id="16">
                          <Controller
                            name={`work_order_items[${index}].stopper`}
                            control={control}
                            defaultValue={field.stopper}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        <td
                          id="17"
                        >
                          <Controller
                            name={`work_order_items[${index}].gate_hook`}
                            control={control}
                            defaultValue={field.gate_hook}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        <td
                          id="17"
                        >
                          <Controller
                            name={`work_order_items[${index}].lock`}
                            control={control}
                            defaultValue={field.lock}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td> */}
                        {/* <td id="18">
                          <Controller
                            name={`work_order_items[${index}].item_cost`}
                            control={control}
                            defaultValue={field.item_cost}
                            render={({ field }) =>
                              <input style={{width:"43px"}} {...field} placeholder="item_cost" />}
                          />
                        </td> */}
                        <td id="18" className="print_hide">
                          {Role != "dealer" ? (
                            <button className="btn btn-sm btn-danger" type="button" onClick={() => remove(index)} >
                              X
                            </button>
                          ) : (
                            <></>
                          )}
                        </td>
                        {/* <td id="18">
                          <Controller
                            name={`rows[${index}].channel_size`}
                            control={control}
                            defaultValue={field.channel_size}
                            render={({ field }) =>
                              <input {...field} placeholder="channel_size" />}
                          />
                        </td> */}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={'20'} className="print_hide">
                        <div className="d-flex justify-content-end">

                          {Role != "dealer" ? (
                            <button className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => append()}
                              style={{ display: 'block', marginBottom: '10px' }}
                            >
                              Add Row
                            </button>
                          ) : (
                            <></>
                          )}




                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>&nbsp;</td>
                      <td style={{ height: "100px" }} className="wrap-nowrap">
                        COMMENT IF ANY :
                      </td>
                      <td colSpan={17} className="text-start">{orderDetail?.note}</td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>

            </div>


            {Role != "dealer" ? (
              <input className="btn btn-success print_hide" type="submit" value="Submit" />
            ) : (
              <></>
            )}
          </AvForm>
        </div>
      )}
    </>
  )
}

export default Update
