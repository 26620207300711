import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { createProductionItemWork, getDealers, getProductionChoices, getWorkers, getWorkOrderChoices } from "store/actions"
import { useForm } from "react-hook-form"


const AssignItemWorkModal = ({ show, onCloseClick, selectedRows }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [selectedWorker, setSelectedWorker] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const { loading, productionChoices, workers } = useSelector(
    state => ({
      loading: state.Orders.loading,
      productionChoices: state?.Production?.productionChoices,
      workers: state?.Workers?.workers,
      // workOrderChoices: state?.WorkOrders?.workOrderChoices,
    }))
  function handleEnters(q) {
    // dispatch(getDealers(q, 1))

  }
  function handleWorkTypeSelect(event) {
    console.log(event);
    setSelectedWorkType({ "label": event.label, "value": event.value })
    setValue('work_type', event["value"]);
  }

  const workTypeOptionGroup = [
    {
      options: productionChoices?.work_types?.map((value, index) => ({
        key: index,
        label: value,
        value: value,
      })),
    },
  ]
  const productionItemStatusGroup = [
    {
      options: productionChoices?.production_status?.map((value, index) => ({
        key: index,
        label: value,
        value: value,
      })),
    },
  ]

  function handleProductionItemStatus(event) {
    setSelectedStatus(event.value)
    setValue('status', event.value);
  }


  function handleEnters(q) {
    // dispatch(getDealers(q, 1))

  }
  function handleWorkerSelect(event) {
    setSelectedWorker({ "label": event.label, "value": event.value })
    setValue('worker', event.value);
  }

  const workerOptionGroup = [
    {
      options: workers?.results?.map((value, index) => ({
        key: index,
        label: `${value["username"]} : (${value["designation_details"].map((item) => item["role"]).join(' , ')})`,
        value: value["id"],
      })),
    },
  ]



  const { control, handleSubmit, register, setValue } = useForm({

    defaultValues: {
      work_type: '',
      worker: '',
      expected_date: ``,
      finished_date: '',
      status: '',
    }
  });

  const onSubmit = (data) => {
    console.log("selectedRows");
    console.log(selectedRows);

    data['production_items'] = selectedRows;
    dispatch(createProductionItemWork(data, history, onCloseClick, "/production-item-works"))
    console.log("data");
    console.log(data);

  };



  useEffect(() => {
    dispatch(getDealers("", 1))
    dispatch(getProductionChoices("", 1))
    dispatch(getWorkers("", 1))
  }, [])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">

        <h4 className="mb-4 text-center font-size-18"> Assign works</h4>
        <AvForm
          className="form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-3">
            <label>Work</label>
            <Select
              onInputChange={handleEnters}
              value={selectedWorkType}
              placeholder={selectedWorkType}
              onChange={handleWorkTypeSelect}
              options={workTypeOptionGroup}
              classNamePrefix="select2-selection"
              isLoading={false}
              required={"required"}
              name={"work_type"}
            />
          </div>

          <div className="mb-3">
            <label>Worker</label>
            <Select
              onInputChange={handleEnters}
              value={selectedWorker}
              placeholder={selectedWorker}
              onChange={handleWorkerSelect}
              options={workerOptionGroup}
              classNamePrefix="select2-selection"
              isLoading={false}
              required={"required"}
              name={"worker"}
            />
          </div>

          <div className="mb-3">
            <AvField
              name="expected_date"
              label="Expected date"
              placeholder="Expected date"
              className="form-control"
              type="date"
              onChange={(e) => setValue('expected_date', e.target.value)}
            />


          </div>

          <div className="mb-3">
            <AvField
              name="finished_date"
              label="Finished date"
              placeholder="Finished date"
              className="form-control"
              type="date"
              onChange={(e) => setValue('finished_date', e.target.value)}

            />
          </div>
          <div className="mb-3">
            <AvField
              name="note"
              label="Note"
              placeholder="Note"
              className="form-control"
              type="textarea"
              onChange={(e) => setValue('note', e.target.value)}

            />
          </div>
          <div className="mb-3">
            <Select
              onInputChange={handleEnters}
              value={selectedStatus}
              placeholder={selectedStatus}
              onChange={handleProductionItemStatus}
              options={productionItemStatusGroup}
              classNamePrefix="select2-selection"
              isLoading={false}
              required={"required"}
              name={"worker"}
            />
          </div>

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block"
              type="submit"
            >
              Submit
            </button>
          </div>

        </AvForm>
      </ModalBody>
    </Modal>
  )
}

AssignItemWorkModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  selectedRows: PropTypes.array
}

export default AssignItemWorkModal
