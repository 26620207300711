import { orderBy } from "lodash"
import {
  GET_WORK_ORDERS_SUCCESS,
  GET_WORK_ORDERS_FAIL,
  GET_WORK_ORDER_DETAIL_SUCCESS,
  GET_WORK_ORDER_DETAIL_FAIL,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAIL,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAIL,
  DELETE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER_FAIL,
  GET_WORK_ORDERS,
  GET_WORK_ORDER_DETAIL,
  UPDATE_WORK_ORDER,
  CREATE_WORK_ORDER,
  DELETE_WORK_ORDER,
  GET_DESIGNATIONS,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAIL,
  GET_WORK_ORDER_CHOICES_SUCCESS,
  GET_WORK_ORDER_CHOICES_FAIL,
  GET_WORK_ORDER_CHOICES,
} from "./actionTypes"

const INIT_STATE = {
  workOrders: {
    count: "",
    next: "",
    previous: "",
    results: [],
  },
  workOrderDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  // createWorkererror: "",
}

const WorkOrders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORK_ORDERS:
    case GET_DESIGNATIONS:
    case UPDATE_WORK_ORDER:
    case CREATE_WORK_ORDER:
    case DELETE_WORK_ORDER:
      return {
        ...state,
        loading: true,
      }
    case GET_WORK_ORDER_CHOICES:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_WORK_ORDER_CHOICES_SUCCESS:
      return {
        ...state,
        workOrderChoices: action.payload,
        loading: false,
      }

    case GET_WORK_ORDER_CHOICES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        workOrderDetail: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_WORK_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_WORK_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        workOrderDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_WORK_ORDER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        workOrderDetail: action.payload,
        loading: false,
        error: {},
      }

    case UPDATE_WORK_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        workOrders: state.workOrders.results.filter(
          order => order.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_WORK_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        designations: action.payload,
        loading: false,
      }

    case GET_DESIGNATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_WORK_ORDER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_WORK_ORDERS_SUCCESS:
      return {
        ...state,
        workOrders: action.payload,
        loading: false,
      }

    case GET_WORK_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default WorkOrders
