import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"

import { getProductionItemDetail} from "store/actions"
import {  useParams } from "react-router-dom"
// import Select from "react-select"
import ProductionItemsTableViewCard from "./ProductionItemsTableViewCard"
const SingleView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  console.log(params.id);

  const { productionItemDetail, loading } = useSelector(
    state => ({
      productionItemDetail: state.Production.productionItemDetail,
      loading: state.Production.loading,
    })
  )

  useEffect(() => {
    dispatch(getProductionItemDetail(params.id))
  }, [params.id, dispatch])


  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className="page-content">
          <ProductionItemsTableViewCard items={[productionItemDetail]} />
        </div>
      )}
    </>
  )
}
export default SingleView
