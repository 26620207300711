import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"

import { getProductionItemWorkDetail} from "store/actions"
import {  useParams } from "react-router-dom"
// import Select from "react-select"
import ProductionItemWorksTableViewCard from "./ProductionItemWorksTableViewCard"

const SingleView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  console.log(params.id);

  const { productionItemWorkDetail, loading } = useSelector(
    state => ({
      productionItemWorkDetail: state.Production.productionItemWorkDetail,
      loading: state.Production.loading,
    })
  )

  useEffect(() => {
    dispatch(getProductionItemWorkDetail(params.id))
    console.log(productionItemWorkDetail);
    
  }, [params.id, dispatch])


  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className="page-content">
          <ProductionItemWorksTableViewCard items={[productionItemWorkDetail]} />
        </div>
      )}
    </>
  )
}
export default SingleView
