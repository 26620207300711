/* WORK_ORDERS */
export const GET_WORK_ORDERS = "GET_WORK_ORDERS"
export const GET_WORK_ORDERS_SUCCESS = "GET_WORK_ORDERS_SUCCESS"
export const GET_WORK_ORDERS_FAIL = "GET_WORK_ORDERS_FAIL"

/* WORK_ORDERS DETAIL*/
export const GET_WORK_ORDER_DETAIL = "GET_WORK_ORDER_DETAIL"
export const GET_WORK_ORDER_DETAIL_SUCCESS = "GET_WORK_ORDER_DETAIL_SUCCESS"
export const GET_WORK_ORDER_DETAIL_FAIL = "GET_WORK_ORDER_DETAIL_FAIL"

/**
 * add WORK_ORDER
 */
export const CREATE_WORK_ORDER = "CREATE_WORK_ORDER"
export const CREATE_WORK_ORDER_SUCCESS = "CREATE_WORK_ORDER_SUCCESS"
export const CREATE_WORK_ORDER_FAIL = "CREATE_WORK_ORDER_FAIL"

/**
 * Edit WORK_ORDER
 */
export const UPDATE_WORK_ORDER = "UPDATE_WORK_ORDER"
export const UPDATE_WORK_ORDER_SUCCESS = "UPDATE_WORK_ORDER_SUCCESS"
export const UPDATE_WORK_ORDER_FAIL = "UPDATE_WORK_ORDER_FAIL"

/**
 * Delete WORK_ORDER
 */
export const DELETE_WORK_ORDER = "DELETE_WORK_ORDER"
export const DELETE_WORK_ORDER_SUCCESS = "DELETE_WORK_ORDER_SUCCESS"
export const DELETE_WORK_ORDER_FAIL = "DELETE_WORK_ORDER_FAIL"


/* DESIGNATIONS */
export const GET_DESIGNATIONS = "GET_DESIGNATIONS"
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS"
export const GET_DESIGNATIONS_FAIL = "GET_DESIGNATIONS_FAIL"


/* WORK_ORDER_CHOICES */
export const GET_WORK_ORDER_CHOICES = "GET_WORK_ORDER_CHOICES"
export const GET_WORK_ORDER_CHOICES_SUCCESS = "GET_WORK_ORDER_CHOICES_SUCCESS"
export const GET_WORK_ORDER_CHOICES_FAIL = "GET_WORK_ORDER_CHOICES_FAIL"
