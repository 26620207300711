import { orderBy } from "lodash"
import {
  GET_PRODUCTION_ITEMS_SUCCESS,
  GET_PRODUCTION_ITEMS_FAIL,
  GET_PRODUCTION_ITEM_DETAIL_SUCCESS,
  GET_PRODUCTION_ITEM_DETAIL_FAIL,
  CREATE_PRODUCTION_ITEM_SUCCESS,
  CREATE_PRODUCTION_ITEM_FAIL,
  UPDATE_PRODUCTION_ITEM_SUCCESS,
  UPDATE_PRODUCTION_ITEM_FAIL,
  DELETE_PRODUCTION_ITEM_SUCCESS,
  DELETE_PRODUCTION_ITEM_FAIL,
  GET_PRODUCTION_ITEMS,
  GET_PRODUCTION_ITEM_DETAIL,
  UPDATE_PRODUCTION_ITEM,
  CREATE_PRODUCTION_ITEM,
  DELETE_PRODUCTION_ITEM,


  GET_PRODUCTION_ITEM_WORKS_SUCCESS,
  GET_PRODUCTION_ITEM_WORKS_FAIL,
  GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS,
  GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL,
  CREATE_PRODUCTION_ITEM_WORK_SUCCESS,
  CREATE_PRODUCTION_ITEM_WORK_FAIL,
  UPDATE_PRODUCTION_ITEM_WORK_SUCCESS,
  UPDATE_PRODUCTION_ITEM_WORK_FAIL,
  DELETE_PRODUCTION_ITEM_WORK_SUCCESS,
  DELETE_PRODUCTION_ITEM_WORK_FAIL,
  GET_PRODUCTION_ITEM_WORKS,
  GET_PRODUCTION_ITEM_WORK_DETAIL,
  UPDATE_PRODUCTION_ITEM_WORK,
  CREATE_PRODUCTION_ITEM_WORK,
  DELETE_PRODUCTION_ITEM_WORK,
  GET_PRODUCTION_CHOICES,
  GET_PRODUCTION_CHOICES_SUCCESS,
  GET_PRODUCTION_CHOICES_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  productionItems: {
    count: "",
    next: "",
    previous: "",
    results: [],
  },
  productionItemDetail: {},
  productionItemWorks: {
    count: "",
    next: "",
    previous: "",
    results: [],
  },
  productionItemWorkDetail: {},
  productionChoices: {},
  error: {},
  loading: false,
  detailLoading: false,
  // createWorkererror: "",
}

const Production = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTION_ITEMS:
    case UPDATE_PRODUCTION_ITEM:
    case CREATE_PRODUCTION_ITEM:
    case DELETE_PRODUCTION_ITEM:
    case GET_PRODUCTION_ITEM_WORKS:
    case UPDATE_PRODUCTION_ITEM_WORK:
    case CREATE_PRODUCTION_ITEM_WORK:
    case DELETE_PRODUCTION_ITEM_WORK:
    case GET_PRODUCTION_CHOICES:

      return {
        ...state,
        loading: true,
      }

    case CREATE_PRODUCTION_ITEM_SUCCESS:
      return {
        ...state,
        productionItemDetail: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_PRODUCTION_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRODUCTION_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        productionItemDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_PRODUCTION_ITEM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_PRODUCTION_ITEM_SUCCESS:
      return {
        ...state,
        productionItemDetail: action.payload,
        loading: false,
        error: {},
      }

    case UPDATE_PRODUCTION_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PRODUCTION_ITEM_SUCCESS:
      return {
        ...state,
        productionItems: state.productionItems.results.filter(
          order => order.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_PRODUCTION_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_PRODUCTION_ITEM_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_PRODUCTION_ITEMS_SUCCESS:
      return {
        ...state,
        productionItems: action.payload,
        loading: false,
      }

    case GET_PRODUCTION_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case CREATE_PRODUCTION_ITEM_WORK_SUCCESS:
      return {
        ...state,
        productionItemWorkDetail: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_PRODUCTION_ITEM_WORK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS:
      return {
        ...state,
        productionItemWorkDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_PRODUCTION_ITEM_WORK_SUCCESS:
      return {
        ...state,
        productionItemWorkDetail: action.payload,
        loading: false,
        error: {},
      }

    case UPDATE_PRODUCTION_ITEM_WORK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PRODUCTION_ITEM_WORK_SUCCESS:
      return {
        ...state,
        productionItemWorks: state.productionItemWorks.results.filter(
          order => order.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_PRODUCTION_ITEM_WORK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_PRODUCTION_ITEM_WORK_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_PRODUCTION_ITEM_WORKS_SUCCESS:
      return {
        ...state,
        productionItemWorks: action.payload,
        loading: false,
      }

    case GET_PRODUCTION_ITEM_WORKS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    
    case GET_PRODUCTION_CHOICES_SUCCESS:
      return {
        ...state,
        productionChoices: action.payload,
        loading: false,
      }

    case GET_PRODUCTION_CHOICES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    default:
      return state
  }
}

export default Production
