/* PRODUCTION_ITEMS */
export const GET_PRODUCTION_ITEMS = "GET_PRODUCTION_ITEMS"
export const GET_PRODUCTION_ITEMS_SUCCESS = "GET_PRODUCTION_ITEMS_SUCCESS"
export const GET_PRODUCTION_ITEMS_FAIL = "GET_PRODUCTION_ITEMS_FAIL"

/* PRODUCTION_ITEMS DETAIL*/
export const GET_PRODUCTION_ITEM_DETAIL = "GET_PRODUCTION_ITEM_DETAIL"
export const GET_PRODUCTION_ITEM_DETAIL_SUCCESS = "GET_PRODUCTION_ITEM_DETAIL_SUCCESS"
export const GET_PRODUCTION_ITEM_DETAIL_FAIL = "GET_PRODUCTION_ITEM_DETAIL_FAIL"

/**
 * add PRODUCTION_ITEM
 */
export const CREATE_PRODUCTION_ITEM = "CREATE_PRODUCTION_ITEM"
export const CREATE_PRODUCTION_ITEM_SUCCESS = "CREATE_PRODUCTION_ITEM_SUCCESS"
export const CREATE_PRODUCTION_ITEM_FAIL = "CREATE_PRODUCTION_ITEM_FAIL"

/**
 * Edit PRODUCTION_ITEM
 */
export const UPDATE_PRODUCTION_ITEM = "UPDATE_PRODUCTION_ITEM"
export const UPDATE_PRODUCTION_ITEM_SUCCESS = "UPDATE_PRODUCTION_ITEM_SUCCESS"
export const UPDATE_PRODUCTION_ITEM_FAIL = "UPDATE_PRODUCTION_ITEM_FAIL"

/**
 * Delete PRODUCTION_ITEM
 */
export const DELETE_PRODUCTION_ITEM = "DELETE_PRODUCTION_ITEM"
export const DELETE_PRODUCTION_ITEM_SUCCESS = "DELETE_PRODUCTION_ITEM_SUCCESS"
export const DELETE_PRODUCTION_ITEM_FAIL = "DELETE_PRODUCTION_ITEM_FAIL"





/* PRODUCTION_ITEM_WORKS */
export const GET_PRODUCTION_ITEM_WORKS = "GET_PRODUCTION_ITEM_WORKS"
export const GET_PRODUCTION_ITEM_WORKS_SUCCESS = "GET_PRODUCTION_ITEM_WORKS_SUCCESS"
export const GET_PRODUCTION_ITEM_WORKS_FAIL = "GET_PRODUCTION_ITEM_WORKS_FAIL"

/* PRODUCTION_ITEM_WORKS DETAIL*/
export const GET_PRODUCTION_ITEM_WORK_DETAIL = "GET_PRODUCTION_ITEM_WORK_DETAIL"
export const GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS = "GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS"
export const GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL = "GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL"

/**
 * add PRODUCTION_ITEM_WORK
 */
export const CREATE_PRODUCTION_ITEM_WORK = "CREATE_PRODUCTION_ITEM_WORK"
export const CREATE_PRODUCTION_ITEM_WORK_SUCCESS = "CREATE_PRODUCTION_ITEM_WORK_SUCCESS"
export const CREATE_PRODUCTION_ITEM_WORK_FAIL = "CREATE_PRODUCTION_ITEM_WORK_FAIL"

/**
 * Edit PRODUCTION_ITEM_WORK
 */
export const UPDATE_PRODUCTION_ITEM_WORK = "UPDATE_PRODUCTION_ITEM_WORK"
export const UPDATE_PRODUCTION_ITEM_WORK_SUCCESS = "UPDATE_PRODUCTION_ITEM_WORK_SUCCESS"
export const UPDATE_PRODUCTION_ITEM_WORK_FAIL = "UPDATE_PRODUCTION_ITEM_WORK_FAIL"

/**
 * Delete PRODUCTION_ITEM_WORK
 */
export const DELETE_PRODUCTION_ITEM_WORK = "DELETE_PRODUCTION_ITEM_WORK"
export const DELETE_PRODUCTION_ITEM_WORK_SUCCESS = "DELETE_PRODUCTION_ITEM_WORK_SUCCESS"
export const DELETE_PRODUCTION_ITEM_WORK_FAIL = "DELETE_PRODUCTION_ITEM_WORK_FAIL"

/**
 * GET PRODUCTION_CHOICES
 */
export const GET_PRODUCTION_CHOICES = "GET_PRODUCTION_CHOICES"
export const GET_PRODUCTION_CHOICES_SUCCESS = "GET_PRODUCTION_CHOICES_SUCCESS"
export const GET_PRODUCTION_CHOICES_FAIL = "GET_PRODUCTION_CHOICES_FAIL"

