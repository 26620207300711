import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_WORK_ORDERS,
  GET_WORK_ORDER_DETAIL,
  CREATE_WORK_ORDER,
  UPDATE_WORK_ORDER,
  DELETE_WORK_ORDER,
  GET_WORK_ORDER_CHOICES,
  // GET_DESIGNATIONS,
} from "./actionTypes"
import {
  getWorkOrdersSuccess,
  getWorkOrdersFail,
  getWorkOrderDetailSuccess,
  getWorkOrderDetailFail,
  createWorkOrderSuccess,
  createWorkOrderFail,
  updateWorkOrderSuccess,
  updateWorkOrderFail,
  deleteWorkOrderSuccess,
  deleteWorkOrderFail,
  getWorkOrderChoicesSuccess,
  // getDesignationsSuccess,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
  Notification,
} from "components/Common/Notification"

const getWorkOrdersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/workorders/workorder/?search=${searchText && searchText}`
    )
  } else {
    return get(`/workorders/workorder/?page=${page ? page : 1}`)
  }
}
const getWorkOrderDetailsAPi = workorderId => {
  return get(`/workorders/workorder/${workorderId}/`)
}
const createWorkOrderApi = ({ workorder }) => {
  return post("/workorders/workorder/", workorder)
}
const updateWorkOrderApi = ({ workorderId, workorder }) => {
  return ApiPut(
    `/workorders/workorder/${workorderId}/`,
    workorder
  )
}
const deleteWorkOrderApi = ({ workorderId }) => {
  return del(`/workorders/workorder/${workorderId}/`)
}

const getWorkOrderChoicesAPi = ({ searchText, page }) => {
  return get(`/workorders/choices/`)
}


function* fetchWorkOrders({ payload }) {
  try {
    const response = yield call(getWorkOrdersAPi, payload)
    yield put(getWorkOrdersSuccess(response))
  } catch (error) {
    // yield put(getWorkOrdersFail(error))
  }
}

function* fetchWorkOrderDetail({ workorderId }) {
  try {
    const response = yield call(getWorkOrderDetailsAPi, workorderId)
    yield put(getWorkOrderDetailSuccess(response))
  } catch (error) {
    yield put(getWorkOrderDetailFail(error))
  }
}
function* onCreateWorkOrder({ payload }) {
  try {
    const response = yield call(createWorkOrderApi, payload)
    if (response?.error_message) {
      yield put(createWorkOrderFail(response))
    } else {
      yield put(createWorkOrderSuccess(response))
      payload.history.push("/workorders")
      Notification({
        type: "success",
        message: "Successfully Created WorkOrder",
        title: "Created!",
      })
    }
  } catch (error) {
    yield put(createWorkOrderFail(error))
    errorNotification()
  }
}

function* onUpdateWorkOrder({ payload }) {
  try {
    const response = yield call(updateWorkOrderApi, payload)
    if (response?.error_message) {
      yield put(createWorkOrderFail(response))
    } else {
      yield put(updateWorkOrderSuccess(response))
      Notification({
        type: "success",
        message: "Successfully Updated WorkOrder",
        title: "Updated!",
      })
    }
  } catch (error) {
    errorNotification()

    yield put(updateWorkOrderFail(error))
  }
}

function* onDeleteWorkOrder({ payload }) {
  try {
    const response = yield call(deleteWorkOrderApi, payload)
    yield put(
      deleteWorkOrderSuccess({ ...response, id: payload.workorderId })
    )
    doneNotification()
    payload.history.push("/workorders")
  } catch (error) {
    errorNotification()

    yield put(deleteWorkOrderFail(error))
  }
}

function* fetchWorkOrderChoices({ payload }) {
  console.log("payload");
  console.log(payload);
  
  try {
    const response = yield call(getWorkOrderChoicesAPi, payload)
    yield put(getWorkOrderChoicesSuccess(response))
  } catch (error) {
    // yield put(getWorkOrdersFail(error))
  }
}


function* workOrederSaga() {
  yield takeEvery(GET_WORK_ORDERS, fetchWorkOrders)
  yield takeEvery(GET_WORK_ORDER_DETAIL, fetchWorkOrderDetail)
  yield takeEvery(CREATE_WORK_ORDER, onCreateWorkOrder)
  yield takeEvery(UPDATE_WORK_ORDER, onUpdateWorkOrder)
  yield takeEvery(DELETE_WORK_ORDER, onDeleteWorkOrder)
  yield takeEvery(GET_WORK_ORDER_CHOICES, fetchWorkOrderChoices)
}

export default workOrederSaga
