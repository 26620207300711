import React, { useEffect, useState } from "react"

import {
  Card,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import ProductionItemsListTable from "./DatatableTables"
import AssignWorkItemModal from "../AssignWorkItemModal"
import { useDispatch, useSelector } from "react-redux"
import { getProductionChoices } from "store/actions"

function ProductionItemsList() {
  const dispatch = useDispatch()
  const [activeTab, setactiveTab] = useState({
    id: "pending",
    title: "pending",
    sort: "pending",
  })
  const [selectedWorkType, setSelectedWorkType] = useState()

  const { loading, productionChoices } = useSelector(
    state => ({
      loading: state.Orders.loading,
      productionChoices: state?.Production?.productionChoices,
    }))
    useEffect(() => {
      dispatch(getProductionChoices("", 1))
    }, [])

  return (
    <>

      <Card>
        <CardBody>
          {/* <h4 className="card-title mb-4">{activeTab.title}</h4> */}

          <Nav pills className="bg-light rounded" role="tablist">
            {productionChoices?.production_status?.map((value, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab.id === value })}
                  onClick={() => {
                    setactiveTab({
                      ...activeTab,
                      id: value,
                      title: value,
                      sort: value,
                    })
                  }}
                >
                  {value.toUpperCase()}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          {/* //TABLE DATA */}
          <TabContent activeTab={activeTab.id} className="mt-4">
            {productionChoices?.production_status?.map((value, index) => (
              <TabPane tabId={value} key={index}>
                {activeTab.id === value && (
                  <ProductionItemsListTable
                  productionStatus={activeTab.sort}
                    title={activeTab.title}
                  />
                )}
              </TabPane>
            ))}

          </TabContent>
        </CardBody>
      </Card>
    </>
  )
}

export default ProductionItemsList
