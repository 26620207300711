import {
  GET_WORKERS,
  GET_WORKERS_SUCCESS,
  GET_WORKERS_FAIL,
  GET_WORKER_DETAIL,
  GET_WORKER_DETAIL_SUCCESS,
  GET_WORKER_DETAIL_FAIL,
  CREATE_WORKER,
  CREATE_WORKER_SUCCESS,
  CREATE_WORKER_FAIL,
  UPDATE_WORKER,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_FAIL,
  DELETE_WORKER,
  DELETE_WORKER_SUCCESS,
  DELETE_WORKER_FAIL,
  GET_DESIGNATIONS,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAIL,
} from "./actionTypes"

export const getWorkers = (searchText, page) => ({
  type: GET_WORKERS,
  payload: { searchText, page },
})

export const getWorkersSuccess = workers => ({
  type: GET_WORKERS_SUCCESS,
  payload: workers,
})
export const getWorkersFail = error => ({
  type: GET_WORKERS_FAIL,
  payload: error,
})

export const getWorkerDetail = workerId => ({
  type: GET_WORKER_DETAIL,
  workerId,
})

export const getWorkerDetailSuccess = workerDetail => ({
  type: GET_WORKER_DETAIL_SUCCESS,
  payload: workerDetail,
})

export const getWorkerDetailFail = error => ({
  type: GET_WORKER_DETAIL_FAIL,
  payload: error,
})

export const createWorker = (worker, history) => ({
  type: CREATE_WORKER,
  payload: { worker, history },
})

export const createWorkerSuccess = worker => ({
  type: CREATE_WORKER_SUCCESS,
  payload: worker,
})

export const createWorkerFail = error => ({
  type: CREATE_WORKER_FAIL,
  payload: error,
})

export const updateWorker = (
  worker,
  workerId,
  history
) => ({
  type: UPDATE_WORKER,
  payload: { worker, workerId, history },
})

export const updateWorkerSuccess = worker => ({
  type: UPDATE_WORKER_SUCCESS,
  payload: worker,
})

export const updateWorkerFail = error => ({
  type: UPDATE_WORKER_FAIL,
  payload: error,
})

export const deleteWorker = (workerId, history) => ({
  type: DELETE_WORKER,
  payload: { workerId, history },
})

export const deleteWorkerSuccess = worker => ({
  type: DELETE_WORKER_SUCCESS,
  payload: worker,
})

export const deleteWorkerFail = error => ({
  type: DELETE_WORKER_FAIL,
  payload: error,
})

export const getDesignations = (searchText, page) => ({
  type: GET_DESIGNATIONS,
  payload: { searchText, page },
})

export const getDesignationsSuccess = designations => ({
  type: GET_DESIGNATIONS_SUCCESS,
  payload: designations,
})
export const getDesignationsFail = error => ({
  type: GET_DESIGNATIONS_FAIL,
  payload: error,
})