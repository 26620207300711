import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"
import PropTypes from "prop-types"
import ProductionItemsTableViewCard from "pages/Production/ProductionItemsCrud/ProductionItemsTableViewCard"
// import ProductionItemWorksViewCard from "../ProductionItemWorksCrud/ProductionItemWorksViewCard"


const WorkOrderImagesTableViewCard = ({ items }) => {
  console.log(items);

  const download = () => {
    window.print()
  }
  return (
    <>
      <div className="" id="res-tab">
        <div className="work-order-images d-flex flex-nowrap">
          {items?.map((value, index) => (
            <img key={index} src={value?.image} />
          ))}
        </div>
      </div>
    </>
  )
}
WorkOrderImagesTableViewCard.propTypes = {
  items: PropTypes.array,
}
export default WorkOrderImagesTableViewCard
