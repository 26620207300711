import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PRODUCTION_ITEMS,
  GET_PRODUCTION_ITEM_DETAIL,
  CREATE_PRODUCTION_ITEM,
  UPDATE_PRODUCTION_ITEM,
  DELETE_PRODUCTION_ITEM,

  GET_PRODUCTION_ITEM_WORKS,
  GET_PRODUCTION_ITEM_WORK_DETAIL,
  CREATE_PRODUCTION_ITEM_WORK,
  UPDATE_PRODUCTION_ITEM_WORK,
  DELETE_PRODUCTION_ITEM_WORK,
  GET_PRODUCTION_CHOICES,
} from "./actionTypes"
import {
  getProductionItemsSuccess,
  getProductionItemDetailSuccess,
  getProductionItemDetailFail,
  createProductionItemSuccess,
  createProductionItemFail,
  updateProductionItemSuccess,
  updateProductionItemFail,
  deleteProductionItemSuccess,
  deleteProductionItemFail,

  getProductionItemWorksSuccess,
  getProductionItemWorkDetailSuccess,
  getProductionItemWorkDetailFail,
  createProductionItemWorkSuccess,
  createProductionItemWorkFail,
  updateProductionItemWorkSuccess,
  updateProductionItemWorkFail,
  deleteProductionItemWorkSuccess,
  deleteProductionItemWorkFail,
  getProductionChoicesSuccess,
  // getDesignationsSuccess,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
  Notification,
} from "components/Common/Notification"

const getProductionItemsAPi = ({ searchText,status,page }) => {
  if (searchText) {
    return get(
      `/production/productionitem/?search=${searchText && searchText}&page=${page ? page : 1}&status=${status}`
    )
  } else {
    return get(`/production/productionitem/?page=${page ? page : 1}&status=${status}`)
  }
}
const getProductionItemDetailsAPi = id => {
  return get(`/production/productionitem/${id}/`)
}
const createProductionItemApi = ({ data }) => {
  console.log(data);
  
  return post("/production/productionitem/", data)
}
const updateProductionItemApi = ({ id, instance }) => {
  return ApiPut(
    `/production/productionitem/${id}/`,
    instance
  )
}
const deleteProductionItemApi = ({ id }) => {
  return del(`/production/productionitem/${id}/`)
}


const getProductionChoicesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/production/choices/?search=${searchText && searchText}`
    )
  } else {
    return get(`/production/choices/?page=${page ? page : 1}`)
  }
}

function* fetchProductionChoices({ payload }) {
  try {
    const response = yield call(getProductionChoicesAPi, payload)
    yield put(getProductionChoicesSuccess(response))
  } catch (error) {
    // yield put(getProductionItemsFail(error))
  }
}


function* fetchProductionItems({ payload }) {
  try {
    const response = yield call(getProductionItemsAPi, payload)
    yield put(getProductionItemsSuccess(response))
  } catch (error) {
    // yield put(getProductionItemsFail(error))
  }
}

function* fetchProductionItemDetail({ id }) {
  try {
    const response = yield call(getProductionItemDetailsAPi, id)
    yield put(getProductionItemDetailSuccess(response))
  } catch (error) {
    yield put(getProductionItemDetailFail(error))
  }
}
function* onCreateProductionItem({ payload }) {
  try {
    const response = yield call(createProductionItemApi, payload)
    if (response?.error_message) {
      yield put(createProductionItemFail(response))
    } else {
      yield put(createProductionItemSuccess(response))
      payload.history.push("/production-items")
      Notification({
        type: "success",
        message: "Successfully Created ProductionItem",
        title: "Created!",
      })
    }
  } catch (error) {
    yield put(createProductionItemFail(error))
    errorNotification()
  }
}

function* onUpdateProductionItem({ payload }) {
  try {
    const response = yield call(updateProductionItemApi, payload)
    if (response?.error_message) {
      yield put(createProductionItemFail(response))
    } else {
      yield put(updateProductionItemSuccess(response))
      Notification({
        type: "success",
        message: "Successfully Updated ProductionItem",
        title: "Updated!",
      })
    }
  } catch (error) {
    errorNotification()

    yield put(updateProductionItemFail(error))
  }
}

function* onDeleteProductionItem({ payload }) {
  try {
    const response = yield call(deleteProductionItemApi, payload)
    yield put(
      deleteProductionItemSuccess({ ...response, id: payload.id })
    )
    doneNotification()
    payload.history.push("/production-items")
  } catch (error) {
    errorNotification()

    yield put(deleteProductionItemFail(error))
  }
}





const getProductionItemWorksAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/production/productionitemwork/?search=${searchText && searchText}`
    )
  } else {
    return get(`/production/productionitemwork/?page=${page ? page : 1}`)
  }
}
const getProductionItemWorkDetailsAPi = id => {
  return get(`/production/productionitemwork/${id}/`)
}
const createProductionItemWorkApi = ({ data }) => {
  return post("/production/productionitemwork/", data)
}
const updateProductionItemWorkApi = ({ id, instance }) => {
  console.log("data //");
  console.log(instance);
  console.log(id);
  
  return ApiPut(
    `/production/productionitemwork/${id}/`,
    instance
  )
}
const deleteProductionItemWorkApi = ({ id }) => {
  return del(`/production/productionitemwork/${id}/`)
}



function* fetchProductionItemWorks({ payload }) {
  try {
    const response = yield call(getProductionItemWorksAPi, payload)
    yield put(getProductionItemWorksSuccess(response))
  } catch (error) {
    // yield put(getProductionItemWorksFail(error))
  }
}

function* fetchProductionItemWorkDetail({ id }) {
  try {
    const response = yield call(getProductionItemWorkDetailsAPi, id)
    yield put(getProductionItemWorkDetailSuccess(response))
  } catch (error) {
    yield put(getProductionItemWorkDetailFail(error))
  }
}
function* onCreateProductionItemWork({ payload }) {
  try {
    const response = yield call(createProductionItemWorkApi, payload)
    if (response?.error_message) {
      yield put(createProductionItemWorkFail(response))
    } else {
      yield put(createProductionItemWorkSuccess(response))
      payload.onCloseClick(false)
      payload.history.push(payload.next)
      Notification({
        type: "success",
        message: "Successfully Created ProductionItemWork",
        title: "Created!",
      })
    }
  } catch (error) {    
    yield put(createProductionItemWorkFail(error))
    errorNotification()
  }
}

function* onUpdateProductionItemWork({ payload }) {
  try {
    const response = yield call(updateProductionItemWorkApi, payload)
    if (response?.error_message) {
      yield put(createProductionItemWorkFail(response))
    } else {
      yield put(updateProductionItemWorkSuccess(response))
      Notification({
        type: "success",
        message: "Successfully Updated ProductionItemWork",
        title: "Updated!",
      })
      payload.history.push(payload.next)
      payload.onCloseClick(false)

    }
  } catch (error) {
    errorNotification()

    yield put(updateProductionItemWorkFail(error))
  }
}

function* onDeleteProductionItemWork({ payload }) {
  try {
    const response = yield call(deleteProductionItemWorkApi, payload)
    yield put(
      deleteProductionItemWorkSuccess({ ...response, id: payload.id })
    )
    doneNotification()
    payload.history.push("/production-item-works")
  } catch (error) {
    errorNotification()

    yield put(deleteProductionItemWorkFail(error))
  }
}


function* productionSaga() {
  yield takeEvery(GET_PRODUCTION_ITEMS, fetchProductionItems)
  yield takeEvery(GET_PRODUCTION_ITEM_DETAIL, fetchProductionItemDetail)
  yield takeEvery(CREATE_PRODUCTION_ITEM, onCreateProductionItem)
  yield takeEvery(UPDATE_PRODUCTION_ITEM, onUpdateProductionItem)
  yield takeEvery(DELETE_PRODUCTION_ITEM, onDeleteProductionItem)

  yield takeEvery(GET_PRODUCTION_CHOICES, fetchProductionChoices)

  yield takeEvery(GET_PRODUCTION_ITEM_WORKS, fetchProductionItemWorks)
  yield takeEvery(GET_PRODUCTION_ITEM_WORK_DETAIL, fetchProductionItemWorkDetail)
  yield takeEvery(CREATE_PRODUCTION_ITEM_WORK, onCreateProductionItemWork)
  yield takeEvery(UPDATE_PRODUCTION_ITEM_WORK, onUpdateProductionItemWork)
  yield takeEvery(DELETE_PRODUCTION_ITEM_WORK, onDeleteProductionItemWork)
}

export default productionSaga
