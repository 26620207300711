/* WORKERS */
export const GET_WORKERS = "GET_WORKERS"
export const GET_WORKERS_SUCCESS = "GET_WORKERS_SUCCESS"
export const GET_WORKERS_FAIL = "GET_WORKERS_FAIL"

/* WORKERS DETAIL*/
export const GET_WORKER_DETAIL = "GET_WORKER_DETAIL"
export const GET_WORKER_DETAIL_SUCCESS =
  "GET_WORKER_DETAIL_SUCCESS"
export const GET_WORKER_DETAIL_FAIL = "GET_WORKER_DETAIL_FAIL"

/**
 * add WORKER
 */
export const CREATE_WORKER = "CREATE_WORKER"
export const CREATE_WORKER_SUCCESS = "CREATE_WORKER_SUCCESS"
export const CREATE_WORKER_FAIL = "CREATE_WORKER_FAIL"

/**
 * Edit WORKER
 */
export const UPDATE_WORKER = "UPDATE_WORKER"
export const UPDATE_WORKER_SUCCESS = "UPDATE_WORKER_SUCCESS"
export const UPDATE_WORKER_FAIL = "UPDATE_WORKER_FAIL"

/**
 * Delete WORKER
 */
export const DELETE_WORKER = "DELETE_WORKER"
export const DELETE_WORKER_SUCCESS = "DELETE_WORKER_SUCCESS"
export const DELETE_WORKER_FAIL = "DELETE_WORKER_FAIL"


/* DESIGNATIONS */
export const GET_DESIGNATIONS = "GET_DESIGNATIONS"
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS"
export const GET_DESIGNATIONS_FAIL = "GET_DESIGNATIONS_FAIL"
