import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Badge, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions

import "../../../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { getProductionItems } from "store/production/actions"
import AssignWorkItemModal from "../AssignWorkItemModal"
import UpdateItemModal from "../UpdateItemModal"

const ProductionItems = ({ productionStatus, title }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedItem, setCheckedItem] = useState({})

  const { productionItems, loading, productionItemWorks } = useSelector(state => ({
    productionItems: state.Production.productionItems,
    loading: state.Production.loading,
    productionItemWorks: state.Production.productionItemWorks
  }))

  const handleModalOpen = (value) => {
    if (checkedRows.length && value == true) {
      setModalIsOpen(true)
    }
    else {
      setModalIsOpen(false)
    }
  }


  //page
  const totalPages = Math.ceil(productionItems?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getProductionItems(searchText, productionStatus, pageSend()))
    console.log("productionItems");
    console.log(productionItems);
  }, [dispatch, page, searchText])

  useEffect(() => {
    setModalIsOpen(false)
    console.log(modalIsOpen);
    console.log(productionItemWorks);

  }, [productionItemWorks])


  const columns = [
    {
      dataField: "sr_no",
      text: "Sr No",
    },
    {
      dataField: "current_work",
      text: "Current work",
      sort: true,
    },
    // {
    //   dataField: "worker",
    //   text: "Worker",
    //   sort: true,
    // },
    // {
    //   dataField: "bill_amount",
    //   text: "Total Amount",
    // },
    {
      dataField: "current_work_status",
      text: "Status",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleUpdateModalOpen = (value, item) => {
    console.log(item);

    setCheckedItem(item)
    if (value == true) {
      setUpdateModalIsOpen(true)
    }
    else {
      setUpdateModalIsOpen(false)
    }
  }

  const productionItemsData = map(productionItems?.results, (item, index) => ({
    ...item,
    key: index,
    // current_work_status: (
    //   <Button className="btn btn-sm btn-rounded btn-info" onClick={() => handleUpdateModalOpen(true, item)}>{item?.current_work_status}</Button>
    // ),
    // status: (

    //   <div
    //     className="d-flex"
    //     style={{
    //       maxWidth: "120px",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //     }}
    //   >
    //     <Badge
    //       className={"font-size-12"}
    //       pill
    //     >
    //       {item.order_status}
    //     </Badge>
    //   </div>
    // ),
    action: (
      <>
        <Link
          type="button"
          className="btn btn-sm btn-info btn-rounded"
          to={`/production-items/${item?.id}`}
        >
          View
        </Link>
      </>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      // Add selected row
      setCheckedRows((prev) => [...prev, row.id]);
    } else {
      // Remove unselected row
      setCheckedRows((prev) => prev.filter((r) => r !== row.id));
    }
    console.log(checkedRows);

  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      // Add all rows
      setCheckedRows(rows.map((row) => row.id));
    } else {
      // Clear all selections
      setCheckedRows([]);
    }
    console.log(checkedRows);

  };

  // Configuration for row selection
  const selectRow = {
    mode: "checkbox", // Enable checkboxes
    clickToSelect: true, // Allow click to select
    style: { backgroundColor: "#c8e6c9" }, // Styling for selected rows
    onSelect: handleSelect, // Triggered on individual row select/deselect
    onSelectAll: handleSelectAll, // Triggered on select/deselect all
  };


  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <AssignWorkItemModal
        show={modalIsOpen}
        onCloseClick={handleModalOpen}
        selectedRows={checkedRows}
      // onDeleteClick={handleDeleteEvent}
      />
      <UpdateItemModal
        show={updateModalIsOpen}
        onCloseClick={handleUpdateModalOpen}
        item={checkedItem}

      // onDeleteClick={handleDeleteEvent}
      />
      <Row>
        {productionItemsData.length > 0 ? (
          <Col className="col-12">
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={productionItemsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {productionStatus === "pending" && (
                          <Row>
                            <Col xl="12">
                              <Button className="btn btn-success" onClick={() => handleModalOpen(true)}>Assign</Button>
                            </Col>
                          </Row>
                        )}

                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Col>
        ) : (
          <p className="text-warning p-4">No {title}</p>
        )}
      </Row>
    </React.Fragment>
  )
}

export default ProductionItems

ProductionItems.propTypes = {
  productionStatus: PropTypes.string,
  title: PropTypes.string,
}
