import {
  GET_PRODUCTION_ITEMS,
  GET_PRODUCTION_ITEMS_SUCCESS,
  GET_PRODUCTION_ITEMS_FAIL,
  GET_PRODUCTION_ITEM_DETAIL,
  GET_PRODUCTION_ITEM_DETAIL_SUCCESS,
  GET_PRODUCTION_ITEM_DETAIL_FAIL,
  CREATE_PRODUCTION_ITEM,
  CREATE_PRODUCTION_ITEM_SUCCESS,
  CREATE_PRODUCTION_ITEM_FAIL,
  UPDATE_PRODUCTION_ITEM,
  UPDATE_PRODUCTION_ITEM_SUCCESS,
  UPDATE_PRODUCTION_ITEM_FAIL,
  DELETE_PRODUCTION_ITEM,
  DELETE_PRODUCTION_ITEM_SUCCESS,
  DELETE_PRODUCTION_ITEM_FAIL,


  GET_PRODUCTION_ITEM_WORKS,
  GET_PRODUCTION_ITEM_WORKS_SUCCESS,
  GET_PRODUCTION_ITEM_WORKS_FAIL,
  GET_PRODUCTION_ITEM_WORK_DETAIL,
  GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS,
  GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL,
  CREATE_PRODUCTION_ITEM_WORK,
  CREATE_PRODUCTION_ITEM_WORK_SUCCESS,
  CREATE_PRODUCTION_ITEM_WORK_FAIL,
  UPDATE_PRODUCTION_ITEM_WORK,
  UPDATE_PRODUCTION_ITEM_WORK_SUCCESS,
  UPDATE_PRODUCTION_ITEM_WORK_FAIL,
  DELETE_PRODUCTION_ITEM_WORK,
  DELETE_PRODUCTION_ITEM_WORK_SUCCESS,
  DELETE_PRODUCTION_ITEM_WORK_FAIL,
  GET_PRODUCTION_CHOICES,
  GET_PRODUCTION_CHOICES_SUCCESS,
  GET_PRODUCTION_CHOICES_FAIL,

} from "./actionTypes"

export const getProductionItems = (searchText,status,page) => ({
  type: GET_PRODUCTION_ITEMS,
  payload: { searchText,status,page },
})

export const getProductionItemsSuccess = payload => ({
  type: GET_PRODUCTION_ITEMS_SUCCESS,
  payload: payload,
})
export const getProductionItemsFail = error => ({
  type: GET_PRODUCTION_ITEMS_FAIL,
  payload: error,
})

export const getProductionItemDetail = id => ({
  type: GET_PRODUCTION_ITEM_DETAIL,
  id,
})

export const getProductionItemDetailSuccess = data => ({
  type: GET_PRODUCTION_ITEM_DETAIL_SUCCESS,
  payload: data,
})

export const getProductionItemDetailFail = error => ({
  type: GET_PRODUCTION_ITEM_DETAIL_FAIL,
  payload: error,
})

export const createProductionItem = (instance, history) => ({
  type: CREATE_PRODUCTION_ITEM,
  payload: { instance, history },
})

export const createProductionItemSuccess = instance => ({
  type: CREATE_PRODUCTION_ITEM_SUCCESS,
  payload: instance,
})

export const createProductionItemFail = error => ({
  type: CREATE_PRODUCTION_ITEM_FAIL,
  payload: error,
})

export const updateProductionItem = (
  instance,
  id,
  history,onCloseClick,next
) => ({
  type: UPDATE_PRODUCTION_ITEM,
  payload: { instance, id, history,onCloseClick,next },
})

export const updateProductionItemSuccess = instance => ({
  type: UPDATE_PRODUCTION_ITEM_SUCCESS,
  payload: instance,
})

export const updateProductionItemFail = error => ({
  type: UPDATE_PRODUCTION_ITEM_FAIL,
  payload: error,
})

export const deleteProductionItem = (id, history) => ({
  type: DELETE_PRODUCTION_ITEM,
  payload: { id, history },
})

export const deleteProductionItemSuccess = instance => ({
  type: DELETE_PRODUCTION_ITEM_SUCCESS,
  payload: instance,
})

export const deleteProductionItemFail = error => ({
  type: DELETE_PRODUCTION_ITEM_FAIL,
  payload: error,
})





export const getProductionItemWorks = (searchText, page) => ({
  type: GET_PRODUCTION_ITEM_WORKS,
  payload: { searchText, page },
})

export const getProductionItemWorksSuccess = payload => ({
  type: GET_PRODUCTION_ITEM_WORKS_SUCCESS,
  payload: payload,
})
export const getProductionItemWorksFail = error => ({
  type: GET_PRODUCTION_ITEM_WORKS_FAIL,
  payload: error,
})

export const getProductionItemWorkDetail = id => ({
  type: GET_PRODUCTION_ITEM_WORK_DETAIL,
  id,
})

export const getProductionItemWorkDetailSuccess = data => ({
  type: GET_PRODUCTION_ITEM_WORK_DETAIL_SUCCESS,
  payload: data,
})

export const getProductionItemWorkDetailFail = error => ({
  type: GET_PRODUCTION_ITEM_WORK_DETAIL_FAIL,
  payload: error,
})

export const createProductionItemWork = (data, history,onCloseClick,next="/production-items") => ({
  type: CREATE_PRODUCTION_ITEM_WORK,
  payload: { data, history,onCloseClick,next },
})

export const createProductionItemWorkSuccess = instance => ({
  type: CREATE_PRODUCTION_ITEM_WORK_SUCCESS,
  payload: instance,
})

export const createProductionItemWorkFail = error => ({
  type: CREATE_PRODUCTION_ITEM_WORK_FAIL,
  payload: error,
})

export const updateProductionItemWork = (
  instance,
  id,
  history,onCloseClick,next
) => ({
  type: UPDATE_PRODUCTION_ITEM_WORK,
  payload: { instance, id, history,onCloseClick,next },
})

export const updateProductionItemWorkSuccess = instance => ({
  type: UPDATE_PRODUCTION_ITEM_WORK_SUCCESS,
  payload: instance,
})

export const updateProductionItemWorkFail = error => ({
  type: UPDATE_PRODUCTION_ITEM_WORK_FAIL,
  payload: error,
})

export const deleteProductionItemWork = (id, history) => ({
  type: DELETE_PRODUCTION_ITEM_WORK,
  payload: { id, history },
})

export const deleteProductionItemWorkSuccess = instance => ({
  type: DELETE_PRODUCTION_ITEM_WORK_SUCCESS,
  payload: instance,
})

export const deleteProductionItemWorkFail = error => ({
  type: DELETE_PRODUCTION_ITEM_WORK_FAIL,
  payload: error,
})

export const getProductionChoices =  (searchText, page ) => ({
  type: GET_PRODUCTION_CHOICES,
  payload: { searchText, page },
})

export const getProductionChoicesSuccess = workorderChoices => ({
  type: GET_PRODUCTION_CHOICES_SUCCESS,
  payload: workorderChoices,
})

export const getProductionChoicesFail = error => ({
  type: GET_PRODUCTION_CHOICES_FAIL,
  payload: error,
})