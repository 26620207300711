import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"
import PropTypes from "prop-types"
import ProductionItemsTableViewCard from "pages/Production/ProductionItemsCrud/ProductionItemsTableViewCard"
// import ProductionItemWorksViewCard from "../ProductionItemWorksCrud/ProductionItemWorksViewCard"


const WorkOrderDetailsViewCard = ({ details }) => {
  console.log("details ...");
  console.log(details);

  const download = () => {
    window.print()
  }
  return (
    <>
      <div className="" id="res-tab">
        <div className="work-order-details-table-parent d-flex mb-4">
          <Table
            id="myTable"
            className="work-order-details-table table table-bordered mb-0 me-4 text-center"
          >
            <thead>
              <tr>
                <th style={{ "width": 180 }}>Order No</th>
                <th>{details.auto_id}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dealer</td>
                <td>{details.dealer_name}</td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td>{details.mobile}</td>
              </tr>
          
              <tr>
                <td>Customer name</td>
                <td>{details.customer_name}</td>
              </tr><tr>
                <td>Billing address</td>
                <td>{details.billing_address}</td>
              </tr>
            </tbody>
          </Table>

          <Table
            id="myTable2"
            className="work-order-details-table table table-bordered mb-0 me-0 text-center"
          >
            <thead>
              <tr>
                <th style={{ "width": 180 }}>Order date</th>
                <th>{details.order_date}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Delivery date</td>
                <td>{details.delivery_date}</td>
              </tr><tr>
                <td>Delivery site</td>
                <td>{details.delivery_site}</td>
              </tr><tr>
                <td>order status</td>
                <td>{details.order_status}</td>
              </tr><tr>
                <td>GST number</td>
                <td>{details.gst_number}</td>
              </tr>
            </tbody>
          </Table>


        </div>
      </div>
    </>
  )
}
WorkOrderDetailsViewCard.propTypes = {
  details: PropTypes.array,
}
export default WorkOrderDetailsViewCard
