import {
  GET_WORKERS_SUCCESS,
  GET_WORKERS_FAIL,
  GET_WORKER_DETAIL_SUCCESS,
  GET_WORKER_DETAIL_FAIL,
  CREATE_WORKER_SUCCESS,
  CREATE_WORKER_FAIL,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_FAIL,
  DELETE_WORKER_SUCCESS,
  DELETE_WORKER_FAIL,
  GET_WORKERS,
  GET_WORKER_DETAIL,
  UPDATE_WORKER,
  CREATE_WORKER,
  DELETE_WORKER,
  GET_DESIGNATIONS,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  workers: {
    count: "",
    next: "",
    previous: "",
    results: [],
  },
  workerDetail: {
    location: "",
    profit: "",
    account: {
      email: "",
      username: "",
      phone: "",
      full_name: "",
    },
    id: "",
  },
  error: {},
  loading: false,
  detailLoading: false,
  // createWorkererror: "",
}

const Workers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORKERS:
    case GET_DESIGNATIONS:
    case UPDATE_WORKER:
    case CREATE_WORKER:
    case DELETE_WORKER:
      return {
        ...state,
        loading: true,
      }
    case GET_WORKER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_WORKERS_SUCCESS:
      return {
        ...state,
        workers: action.payload,
        loading: false,
      }

    case GET_WORKERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_WORKER_SUCCESS:
      return {
        ...state,
        workers: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_WORKER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_WORKER_DETAIL_SUCCESS:
      return {
        ...state,
        workerDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_WORKER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_WORKER_SUCCESS:
      return {
        ...state,
        workerDetail: action.payload,
        loading: false,
        error: {},
      }

    case UPDATE_WORKER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_WORKER_SUCCESS:
      return {
        ...state,
        workers: state.workers.results.filter(
          worker => worker.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_WORKER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        designations: action.payload,
        loading: false,
      }

    case GET_DESIGNATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Workers
