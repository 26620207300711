import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_WORKERS,
  GET_WORKER_DETAIL,
  CREATE_WORKER,
  UPDATE_WORKER,
  DELETE_WORKER,
  GET_DESIGNATIONS,
} from "./actionTypes"
import {
  getWorkersSuccess,
  getWorkersFail,
  getWorkerDetailSuccess,
  getWorkerDetailFail,
  createWorkerSuccess,
  createWorkerFail,
  updateWorkerSuccess,
  updateWorkerFail,
  deleteWorkerSuccess,
  deleteWorkerFail,
  getDesignationsSuccess,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
  Notification,
} from "components/Common/Notification"

const getWorkersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/account/worker/?search=${searchText && searchText}`
    )
  } else {
    return get(`/account/worker/?page=${page ? page : 1}`)
  }
}
const getWorkerDetailsAPi = workerId => {
  return get(`/account/worker/${workerId}/`)
}
const createWorkerApi = ({ worker }) => {
  return post("/account/worker/", worker)
}
const updateWorkerApi = ({ workerId, worker }) => {
  return ApiPut(
    `/account/worker/${workerId}/`,
    worker
  )
}
const deleteWorkerApi = ({ workerId }) => {
  return del(`/account/worker/${workerId}/`)
}


const getDesignationsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/account/designation/?search=${searchText && searchText}`
    )
  } else {
    return get(`/account/designation/?page=${page ? page : 1}`)
  }
}


function* fetchWorkers({ payload }) {
  try {
    const response = yield call(getWorkersAPi, payload)
    yield put(getWorkersSuccess(response))
  } catch (error) {
    // yield put(getWorkersFail(error))
  }
}

function* fetchWorkerDetail({ workerId }) {
  try {
    const response = yield call(getWorkerDetailsAPi, workerId)
    yield put(getWorkerDetailSuccess(response))
  } catch (error) {
    yield put(getWorkerDetailFail(error))
  }
}
function* onCreateWorker({ payload }) {
  try {
    const response = yield call(createWorkerApi, payload)
    if (response?.error_message) {
      yield put(createWorkerFail(response))
    } else {
      yield put(createWorkerSuccess(response))
      payload.history.push("/workers")
      Notification({
        type: "success",
        message: "Successfully Created Worker",
        title: "Created!",
      })
    }
  } catch (error) {
    yield put(createWorkerFail(error))
    errorNotification()
  }
}

function* onUpdateWorker({ payload }) {
  try {
    const response = yield call(updateWorkerApi, payload)
    if (response?.error_message) {
      yield put(createWorkerFail(response))
    } else {
      yield put(updateWorkerSuccess(response))
      Notification({
        type: "success",
        message: "Successfully Updated Worker",
        title: "Updated!",
      })
    }
  } catch (error) {
    errorNotification()

    yield put(updateWorkerFail(error))
  }
}

function* onDeleteWorker({ payload }) {
  try {
    const response = yield call(deleteWorkerApi, payload)
    yield put(
      deleteWorkerSuccess({ ...response, id: payload.workerId })
    )
    doneNotification()
    payload.history.push("/workers")
  } catch (error) {
    errorNotification()

    yield put(deleteWorkerFail(error))
  }
}


function* fetchDesignations({ payload }) {
  try {
    const response = yield call(getDesignationsAPi, payload)
    yield put(getDesignationsSuccess(response))
  } catch (error) {
    // yield put(getWorkersFail(error))
  }
}


function* workersSaga() {
  yield takeEvery(GET_WORKERS, fetchWorkers)
  yield takeEvery(GET_WORKER_DETAIL, fetchWorkerDetail)
  yield takeEvery(CREATE_WORKER, onCreateWorker)
  yield takeEvery(UPDATE_WORKER, onUpdateWorker)
  yield takeEvery(DELETE_WORKER, onDeleteWorker)
  yield takeEvery(GET_DESIGNATIONS, fetchDesignations)
}

export default workersSaga
