import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

//actions
import { getDesignations, getWorkerDetail, updateWorker } from "store/actions"

//componets

const UpdateWorker = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, workerDetail, designationsOptions, error } = useSelector(state => ({
    loading: state.Storemngrs.loading,
    workerDetail: state.Workers.workerDetail,
    designationsOptions: state.Workers.designations,
    error: state.Workers.error,
  }))

  const [selectedDesignations, setSelectedDesignations] = useState([])

  const handleCheckboxChange = (event) => {

    const { name, checked, value } = event.target;
      setSelectedDesignations((prev) =>
        prev.map((row) =>
          row.id === name ? { ...row, selected: checked } : row
        )
      );

  }

  function handleValidSubmit(values) {
    values["designation"] = selectedDesignations
    .filter((item) => item.selected) // Keep only selected items
    .map((item) => item.id); 
    dispatch(updateWorker(values, workerDetail.id, history))
    window.scroll(0, 0)
  }


  useEffect(() => {
    dispatch(getWorkerDetail(params.id))
    dispatch(getDesignations())
  }, [dispatch])


  useEffect(() => {
    const selectedOptionIds = workerDetail?.designation_details ? workerDetail.designation_details.map((option) => option.id) : [];
    const updatedOptions = designationsOptions?.map((option) => ({
      ...option,
      selected: selectedOptionIds.includes(option.id),
    }));
    setSelectedDesignations(updatedOptions)
  }, [designationsOptions, workerDetail])

  const usernameError = error?.username && error?.username[0]
  const mailError = error?.email && error?.email[0]

  return (
    <>
      <MetaTags>
        <title>Worker | Loha</title>
      </MetaTags>

      <div className="page-content">
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={12}>
                <Card style={{ padding: "3rem" }}>
                  <CardTitle className="h4 mb-4">Update Profile</CardTitle>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          User name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="username"
                            type="text"
                            value={workerDetail?.username}
                            validate={{
                              required: { value: true },
                            }}
                          />
                          {usernameError && (
                            <Alert color="danger" className="mt-2">
                              {usernameError}
                            </Alert>
                          )}
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label" >
                          Full name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-firstname-Input"
                            name="full_name"
                            value={workerDetail?.full_name}
                            className="filePathClass"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                        
                      <div className="row mb-4">
                        <Label
                          htmlFor="tel-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone No
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="phone"
                            className="form-control"
                            id="tel-input"
                            value={workerDetail?.phone}
                            type="mobile"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter your phone number",
                              },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="email"
                            value={workerDetail?.email}
                            className="form-control"
                            type="email"
                            required
                          />
                          {mailError && (
                            <Alert color="danger" className="mt-2">
                              {mailError}
                            </Alert>
                          )}
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-designation-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Roles
                        </Label>
                        {/* <Col sm={9}>
                          <AvField
                            id="horizontal-designation-Input"
                            name="designation"
                            type="select"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">Please select an option</option>
                            {designationsOptions?.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.role}
                              </option>
                            ))}
                          </AvField>
                        </Col> */}

                        <Col sm={9}>
                          <Label for="designation"></Label>

                          {selectedDesignations?.length ? selectedDesignations?.map((option, index) => {
                            return (
                              <div key={index} className="d-flex mb-2">
                                <AvField
                                  id={`designation-${option.id}`}
                                  type="checkbox"
                                  name={option?.id}
                                  value={option?.selected}
                                  checked={option?.selected}
                                  onChange={handleCheckboxChange}
                                />

                                <label htmlFor={`designation-${option.id}`} className="form-check-label">
                                  {option?.role}
                                </label>
                              </div>
                            )
                          }) : ""}

                          {selectedDesignations?.length === 0 ? (<p className="text-danger mt-2">Please select at least one role.</p>) : ""}
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="success"
                              className="w-md"
                            >
                              Update
                              {loading && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              )}
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateWorker
