import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"

import logo from "../../../assets/images/logo/LOHA.png"
import isoBadge from "../../../assets/images/iso-badge.png"
import { createWorkOrder, getWorkOrderChoices } from "store/actions"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { getDealers } from "store/actions"

const Create = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderDetail, loading, orderitemLoading, dealers, workOrderChoices } = useSelector(
    state => ({
      orderDetail: state.Orders.orderDetail,
      orderitem: state.Orders.orderDetail.orderitem,
      loading: state.Orders.loading,
      orderitemLoading: state.Orders.orderitemLoading,
      dealers: state?.Dealers?.dealers,
      workOrderChoices: state?.WorkOrders?.workOrderChoices,
    })
  )

  // const [orderitem, setOrderitem] = useState([1,2])

  const [showEditInput, setShowEditInput] = useState("")
  const [itemQty, setItemQty] = useState("")
  const [selectedDealer, setSelectedDealer] = useState({ "label": "Select dealer", "value": "10a4ea9c-4c98-4708-a704-1bb1f1891089" })
  const [selectedImages, setSelectedImages] = useState([])
  // const [selectedImagePreviews, setSelectedImagePreviews] = useState([])
  // const [dealers, setDealers] = useState([{ "label": "Harshad", "mobile": "", "value": "c8bae754-2ecc-44f2-83ca-560f1dc200a1" }, { "label": "Hashid", "mobile": "", "value": "129add45-6d31-4b89-9704-ad5651cb65ef" }])


  // const [orderDate, setOrderDate] = useState()
  // const [customerName, setCustomerName] = useState()
  // const [delivaryDate, setDelivaryDate] = useState()
  // const [billingAddress, setBillingAddress] = useState()
  // const [delivarySite, setDelivarySite] = useState()
  // const [gstNumber, setGstNumber] = useState()

  //   useEffect(() => {
  //     setTimeout(() => {
  //       window.print()
  //     }, 2000)
  //   }, [])

  const download = () => {
    window.print()
  }

  // function userExists(arr, name) {
  //   const isExist = arr.some(function (el) {
  //     return el.addon_name === name
  //   })
  //   return { isExist: isExist, data: arr.filter(i => i.addon_name === name)[0] }
  // }

  // const handleOrderItemAddon = arr => {
  //   const modifiedArr = arr?.filter(
  //     i => i?.addon_name !== "Manchary" && i?.addon_name !== "Panel"
  //   )
  //   return modifiedArr
  // }

  // const handleAddonTd = item => {
  //   if (item?.addon_name === "Manchary") {
  //     return <td>9</td>
  //   }
  // }

  useEffect(() => {
    dispatch(getDealers("", 1))
    dispatch(getWorkOrderChoices("", 1))
    // console.log(workOrderChoices);

  }, [])

  useEffect(() => {
    // console.log(workOrderChoices);
    // console.log(control);
    // console.log(fields);


  }, [workOrderChoices])


  useEffect(() => {
    setValue('work_order_images', selectedImages);
  }, [selectedImages])

  // const handleChangeQty = (e, id) => {
  //   setItemQty({
  //     ...itemQty,
  //     qty: e.target.value,
  //     itemId: id,
  //   })
  // }

  // const onSubmitQty = e => {
  //   e.preventDefault()
  //   dispatch(updateOrderItem("", itemQty?.itemId, itemQty?.qty))
  //   setShowEditInput("")
  // }

  // const handleShowInput = (id, qty) => {
  //   setItemQty({
  //     ...itemQty,
  //     qty: qty,
  //     itemId: id,
  //   })
  //   setShowEditInput(id)
  // }



  function handleEnters(q) {
    dispatch(getDealers(q, 1))

  }
  function handleDealerSelect(event) {
    setSelectedDealer({ "label": event.label, "value": event.value })
    setValue('mobile', event.mobile);
  }
  const dealerOptionGroup = [
    {
      options: dealers?.results?.map((result, index) => ({
        key: index,
        label: `${result?.account.username}`,
        value: result?.id,
        mobile: result?.account?.phone
      })),
    },
  ]



  const handleImages = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Converts file to base64 string
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    });

    Promise.all(promises)
      .then(base64Files => {
        console.log("base64Files");
        console.log(base64Files);

        setSelectedImages((prevImages) => [...prevImages, { image: base64Files[0] }]);
      })
      .catch((error) => {
        console.error('Error converting files to base64:', error);
      });
  };


  // function handleImages(event) {
  //   console.log(selectedImages);
  //   setSelectedImages((prevImages) => [...prevImages, event.target.files[0]]);
  //   console.log(event);
  //   console.log(selectedImages);
  //   // setRawData({
  //   //   ...rawData,
  //   //   orderitem: {
  //   //     ["product"]: event.value,
  //   //     ["productName"]: event.label,
  //   //   },
  //   // })
  //   // dispatch(getAddons(event?.value))
  // }

  const handleRemoveImage = (index) => {
    // Remove the image by filtering out the selected index
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
    console.log(index);
  };

  const current = new Date();

  const { control, handleSubmit, register, setValue } = useForm({
    // values: {
    //   work_order_images: selectedImages,
    // },

    defaultValues: {
      title: 'test title',
      dealer: selectedDealer?.value,
      order_date:`${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,
      customer_name: '',
      delivery_date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,
      delivery_site: '',
      billing_address: '',
      gst_number: '',
      total_cost: 0,
      work_order_items: [{
        channel_size: '',
        channel_guage: '',
        particulars: '',
        width: '',
        height: '',
        grill_type: '',
        manchary: false,
        louvers: false,
        panel: 'normal',
        design: 'normal',
        divider: 0,
        open_side: '',
        doorsill: false,
        quantity: 0,
        hinges: 0,
        stopper: 0,
        gate_hook: 0,
        lock: 0,
        item_cost: 0,
        item_price: 0,
      },],
      // work_order_images: selectedImages
    }
  });

  // useEffect(() => {
  //   setValue('work_order_images', selectedImages); // Update the work_order_images field in the form
  // }, [selectedImages, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'work_order_items', // Name must match the array field in useForm
  });


  const onSubmit = (data) => {
    data['dealer'] = selectedDealer?.value;
    dispatch(createWorkOrder(data, history))
    console.log("data");
    console.log(data);

  };

  // selectedImages.map((image, index) => {
  //   console.log("image");
  //   console.log(image);
  // })



  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className="page-content print_bg_white">
          <div className="d-flex align-items-center justify-content-end pt-5 print_hide">
            <div
              type="button"
              className="btn btn-outline-info d-flex display-none me-3"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={() => history.push(`/order/update/${orderDetail?.id}`)}
            >
              Edit Order items
              <i className="mdi mdi-pen d-block font-size-16 mx-1"></i>
            </div>

            <div
              type="button"
              className="btn btn-outline-info d-flex display-none"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={download}
            >
              PDF
              <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-4 px-2">
            <div className="w-33"><img
              src={isoBadge}
              alt="image"
              // className="display-block d-none"
              height={80}
              width={80}
            /></div>
            <div
              className="d-flex align-items-center w-33"
              style={{ flexDirection: "column" }}
            >
              <img
                src={logo}
                alt="image"
                // className="display-block d-none"
                height={50}
                width={200}
              />
              <p className="pt-1 mb-0 pb-0">Vengara, Malappuram</p>
              {/* <p className="m-0 p-0">DT. Kerala(32)-676304</p> */}
              <a href="tel:+919288022780">PH: +91 9288 022 780</a>
              <a href="mailto:lohasteelsales@gmail.com">
                Lohasteelsales@gmail.com
              </a>
            </div>
            <div className="w-33 d-flex align-items-center justify-content-end order-number">
              <div className="w-fit">
                <p className="d-flex order_border mb-0 "> Order No : </p>
                <input placeholder="Order No" />
              </div>
            </div>
          </div>


          <AvForm className="workorder-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between align-items-stretch">
              <div className="table-responsive left-box">
                <Table className="table address_table table-bordered mb-0 w-100">
                  <tbody>
                    <tr>
                      <th>DEALER</th>
                      <td>
                        <Select
                          onInputChange={handleEnters}
                          value={selectedDealer}
                          placeholder={selectedDealer}
                          onChange={handleDealerSelect}
                          options={dealerOptionGroup}
                          classNamePrefix="select2-selection"
                          isLoading={false}
                          required={"required"}
                          name={"dealer"}
                        />

                        {/* <input
                          {...register('gst_number')}
                          placeholder="gst_number"
                          style={{ marginLeft: '10px' }}
                        /> */}


                      </td>
                    </tr>

                    <tr>
                      <th scope="row">MOB</th>
                      <td>
                        <input
                          {...register('mobile')}
                          placeholder="mobile"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">ORDER DATE</th>
                      <td>
                        <input type="date"
                          {...register('order_date')}
                          placeholder="order_date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CUSTOMER NAME</th>
                      <td>
                        <input 
                          {...register('customer_name')}
                          placeholder="customer_name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY DATE</th>
                      <td>

                        {/* {moment(orderDetail?.end_date)?.format("DD-MM-yyyy")} */}

                        <input type="date"
                          {...register('delivery_date')}
                          placeholder="delivery_date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY SITE</th>
                      <td>
                        <input
                          {...register('delivery_site')}
                          placeholder="delivery_site"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">BILLING ADDRESS</th>
                      <td>
                        <input
                          {...register('billing_address')}
                          placeholder="billing_address"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">GST NUMBER</th>
                      <td>
                        <input
                          {...register('gst_number')}
                          placeholder="gst_number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

              </div>
              <div className="table-responsive w-100 align-items-stretch">
                <Table className="table table-bordered mb-0 w-100">
                  <thead>
                    <tr>
                      <th className="text-center p-0">Drawings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedImages.length > 0 &&
                          selectedImages.map((image, index) => (
                            <div className="image-container" key={index} style={{ margin: '10px' }}>
                              <img
                                src={image.image}
                                alt={`Selected file ${index + 1}`}
                                width="100"
                                onLoad={() => image} // Revoke object URL to release memory
                              />
                              <span className="print_hide"
                                onClick={() => handleRemoveImage(index)}
                                disabled

                              >
                                X
                              </span>
                            </div>
                          ))}

                      </td>
                    </tr>
                    <tr className="print_hide">
                      <td>
                        <AvField
                          className=""
                          type={"file"}
                          name={"work_order_image"}
                          errorMessage="Invalid Input"
                          placeholder={"Drawings"}
                          onChange={e =>
                            handleImages(
                              e
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>CUTTING</th>
                    <th>BENDING</th>
                    <th>PRODUCT</th>
                    <th>PAINTING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "89px" }}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </Table> */}
              </div>
            </div>

            <div className="row-box">
              <div className="d-flex overflow-x-scroll print_scroll_hide" id="res-tab">
                <Table
                  id="myTable"
                  className="table order_item_table table-bordered mb-0 text-center"
                >
                  <thead>
                    <tr>
                      <th id="1">Sl No:</th>
                      <th id="2">Channel
                        Size</th>
                      <th id="2.5">Gauge</th>
                      <th id="3">Particulars</th>
                      <th id="4">Width</th>
                      <th id="5">Height</th>
                      <th id="6">Grill Type</th>
                      <th id="7">Manchary</th>
                      <th id="7">Louvers</th>
                      <th id="8">Panel</th>
                      <th id="9">Design</th>
                      <th id="13">Divider</th>
                      <th id="15">OpenSide</th>
                      <th id="7">Doorsill</th>
                      <th id="17">Quantity</th>
                      <th id="17">Hinges</th>
                      {/* <th id="17">Stopper</th>
                      <th id="17">Gate
                        Hook</th>
                      <th id="17">Lock</th> */}
                      <th id="18" className="print_hide"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(fields, (field, index) => (
                      <tr className="workorderitem" id={field.id} key={index}>
                        <td id="1">{index + 1}</td>
                        <td id="2" className="text-red">
                          <Controller
                            name={`work_order_items[${index}].channel_size`}
                            control={control}
                            defaultValue={field.channel_size || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.channel_size?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="2.5">
                          <Controller
                            name={`work_order_items[${index}].channel_guage`}
                            control={control}
                            defaultValue={field.channel_guage || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "70px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.channel_guage?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="3">
                          <Controller
                            name={`work_order_items[${index}].particulars`}
                            control={control}
                            defaultValue={field.particulars}
                            render={({ field }) =>
                              <input style={{ width: "120px" }} {...field} />}
                          />
                        </td>
                        <td id="4">
                          <Controller
                            name={`work_order_items[${index}].width`}
                            control={control}
                            defaultValue={field.width}
                            render={({ field }) =>
                              <input style={{ width: "60px" }} {...field} />}
                          />
                        </td>
                        <td id="5">
                          <Controller
                            name={`work_order_items[${index}].height`}
                            control={control}
                            defaultValue={field.height}
                            render={({ field }) =>
                              <input style={{ width: "60px" }} {...field} />}
                          />
                        </td>
                        <td id="6">
                          <Controller
                            name={`work_order_items[${index}].grill_type`}
                            control={control}
                            defaultValue={field.grill_type || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.grill_type?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="7">
                          <Controller
                            name={`work_order_items[${index}].manchary`}
                            control={control}
                            defaultValue={field.manchary || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} />
                            )}
                          />


                        </td>
                        <td id="8">
                          <Controller
                            name={`work_order_items[${index}].louvers`}
                            control={control}
                            defaultValue={field.louvers || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} />
                            )}
                          />
                        </td>
                        <td id="9">
                          <Controller
                            name={`work_order_items[${index}].panel`}
                            control={control}
                            defaultValue={field.panel || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.panel_type?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="10">
                          <Controller
                            name={`work_order_items[${index}].design`}
                            control={control}
                            defaultValue={field.design || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.design_type?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>
                        <td id="11">
                          <Controller
                            name={`work_order_items[${index}].divider`}
                            control={control}
                            defaultValue={field.divider}
                            render={({ field }) =>
                              <input type="number" style={{ width: "42px" }} {...field} />}
                          />
                        </td>
                        <td id="12">
                          <Controller
                            name={`work_order_items[${index}].open_side`}
                            control={control}
                            defaultValue={field.open_side || ''} // Make sure default value is handled
                            render={({ field }) => (
                              <select style={{ width: "80px" }} {...field}> {/* Use 'field' from Controller */}
                                <option value="">...</option>
                                {workOrderChoices?.open_side?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        </td>

                        <td id="13">
                          <Controller
                            name={`work_order_items[${index}].doorsill`}
                            control={control}
                            defaultValue={field.doorsill || false}
                            render={({ field }) => (
                              <input type="checkbox" {...field} />
                            )}
                          />
                        </td>
                        <td id="14">
                          <Controller
                            name={`work_order_items[${index}].quantity`}
                            control={control}
                            defaultValue={field.quantity}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        <td id="15">
                          <Controller
                            name={`work_order_items[${index}].hinges`}
                            control={control}
                            defaultValue={field.hinges}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td>
                        {/* <td id="16">
                          <Controller
                            name={`work_order_items[${index}].stopper`}
                            control={control}
                            defaultValue={field.stopper}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td> */}
                        {/* <td
                          id="17"
                        >
                          <Controller
                            name={`work_order_items[${index}].gate_hook`}
                            control={control}
                            defaultValue={field.gate_hook}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td> */}
                        {/* <td
                          id="17"
                        >
                          <Controller
                            name={`work_order_items[${index}].lock`}
                            control={control}
                            defaultValue={field.lock}
                            render={({ field }) =>
                              <input type="number" style={{ width: "43px" }} {...field} />}
                          />
                        </td> */}
                        {/* <td id="18">
                          <Controller
                            name={`work_order_items[${index}].item_cost`}
                            control={control}
                            defaultValue={field.item_cost}
                            render={({ field }) =>
                              <input style={{width:"43px"}} {...field} placeholder="item_cost" />}
                          />
                        </td> */}
                        <td id="18" className="print_hide">
                          <button className="btn btn-sm btn-danger" type="button" onClick={() => remove(index)} >
                            X
                          </button>
                        </td>
                        {/* <td id="18">
                          <Controller
                            name={`rows[${index}].channel_size`}
                            control={control}
                            defaultValue={field.channel_size}
                            render={({ field }) =>
                              <input {...field} placeholder="channel_size" />}
                          />
                        </td> */}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={'20'} className="print_hide">
                        <div className="d-flex justify-content-end">

                          <button className="btn btn-sm btn-primary"
                            type="button"
                            onClick={() => append()}
                            style={{ display: 'block', marginBottom: '10px' }}
                          >
                            Add Row
                          </button>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>&nbsp;</td>
                      <td style={{ height: "100px" }} className="wrap-nowrap">
                        COMMENT IF ANY :
                      </td>
                      <td colSpan={17} className="text-start">{orderDetail?.note}</td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
              {/* <div className="table-responsive d-flex align-items-center justify-content-end ">
                <Table className="table table-bordered mb-0 w-50 ">
                  <thead>
                    <tr>
                      <th className="bg-light">ORDER</th>
                      <th className="bg-light">PROGRAMME</th>
                      <th className="bg-light">CUTTING</th>
                      <th className="bg-light">SUPERVISOR</th>
                      <th className="bg-light">QC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "60px" }}>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </Table>
              </div> */}
              {/* <CardSubtitle className="mb-2 mt-0">
              <div
                style={{
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
                className="d-flex"
              >
                <div className="width-45">
                  <p className="text-muted mb-0 pb-0">
                    Dealer :{" "}
                    <CardTitle className="mb-0 pb-0 text-bol text-black">
                      {orderDetail?.dealer_name}
                    </CardTitle>
                  </p>
                  <p className="m-0 p-0">{orderDetail?.dealer_location}</p>
                  <p style={{ fontWeight: "bold" }}>
                    <i className="bx bx-phone-call"></i>{" "}
                    {orderDetail?.dealer_phone}
                  </p>
                </div>

                <div style={{ width: "fit-content" }}>
                  <p className="mb-0 ">
                    Order date :{" "}
                    <span className="font-bold-500">
                      {moment(orderDetail?.date_added)?.format("DD-MM-yyyy")}
                    </span>
                  </p>
                  <p>
                    Delivery Date :{" "}
                    <span className="end_date_text">
                      {moment(orderDetail?.end_date)?.format("DD-MM-yyyy")}
                    </span>
                  </p>
                </div>
              </div>
            </CardSubtitle> */}

              {/* {map(orderitem, (item, key) => (
              <div className="pt-2 pb-2 mt-0" key={key}>
                <div
                  style={{
                    border: "1px solid #0000001f",
                    padding: "1rem",
                  }}
                >
                  <div
                    className="d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="d-flex">
                      <p className="mx-2">{key + 1}</p>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Serial Number</p>
                        <CardTitle className="color-blue">
                          {item?.product_details?.name}{" "}
                          {item?.product_details?.serial_number}
                        </CardTitle>
                      </div>

                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Channel Size </p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.channel_size}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Guage</p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.guage}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Column</p>
                        <CardTitle className="color-blue">
                          {item?.product_details?.perticular}{" "}
                          {item?.product_details?.no_of_cols}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Height</p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.height}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Width</p>
                        <CardTitle className="mb-0 color-blue">
                          {" "}
                          {item?.product_details?.width}
                        </CardTitle>
                      </div>
                    </div>
                    <div className="text-center custom_border">
                      quantity
                      <h4>{item?.quantity} </h4>
                    </div>
                  </div>

                  <div className="d-flex mx-4 px-2 mt-0 mb-0">
                    <div className="d-flex font-size-14">
                      Panel :{" "}
                      <CardTitle className="mx-2 color-blue">
                        {userExists(item?.orderitemaddon, "Panel").isExist
                          ? "Yes"
                          : "No"}
                      </CardTitle>
                    </div>
                    <div className="mx-4 d-flex font-size-14">
                      Manchary :{" "}
                      <CardTitle className="mx-2 color-blue">
                        {userExists(item?.orderitemaddon, "Manchary").isExist
                          ? "Yes"
                          : "No"}
                      </CardTitle>
                    </div>
                  </div>

                  <div className={`mx-3 d-flex flex-wrap`}>
                    {map(handleOrderItemAddon(item?.orderitemaddon), addon => (
                      <div className="mt-1 mx-1 w-33 mb-1" key={addon?.id}>
                        <div className="bg-light bg-info-new  p-3 pb-1">
                          <CardTitle className="color-blue">
                            {addon?.addon_name}-
                            {addon?.quantity ? (
                              <span> {addon?.quantity}</span>
                            ) : (
                              ""
                            )}
                          </CardTitle>

                          <p
                            style={{ fontSize: "12px" }}
                            className="text-muted"
                          >
                            {addon?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))} */}
            </div>
            <input className="btn btn-success print_hide" type="submit" value="Submit" />
          </AvForm>
        </div>
      )}
    </>
  )
}

export default Create
