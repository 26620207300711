import {
  GET_WORK_ORDERS,
  GET_WORK_ORDERS_SUCCESS,
  GET_WORK_ORDERS_FAIL,
  GET_WORK_ORDER_DETAIL,
  GET_WORK_ORDER_DETAIL_SUCCESS,
  GET_WORK_ORDER_DETAIL_FAIL,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAIL,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAIL,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER_FAIL,
  GET_WORK_ORDER_CHOICES,
  GET_WORK_ORDER_CHOICES_SUCCESS,
  GET_WORK_ORDER_CHOICES_FAIL
} from "./actionTypes"

export const getWorkOrders = (searchText, page) => ({
  type: GET_WORK_ORDERS,
  payload: { searchText, page },
})

export const getWorkOrdersSuccess = workorders => ({
  type: GET_WORK_ORDERS_SUCCESS,
  payload: workorders,
})
export const getWorkOrdersFail = error => ({
  type: GET_WORK_ORDERS_FAIL,
  payload: error,
})

export const getWorkOrderDetail = workorderId => ({
  type: GET_WORK_ORDER_DETAIL,
  workorderId,
})

export const getWorkOrderDetailSuccess = workorderDetail => ({
  type: GET_WORK_ORDER_DETAIL_SUCCESS,
  payload: workorderDetail,
})

export const getWorkOrderDetailFail = error => ({
  type: GET_WORK_ORDER_DETAIL_FAIL,
  payload: error,
})

export const createWorkOrder = (workorder, history) => ({
  type: CREATE_WORK_ORDER,
  payload: { workorder, history },
})

export const createWorkOrderSuccess = workorder => ({
  type: CREATE_WORK_ORDER_SUCCESS,
  payload: workorder,
})

export const createWorkOrderFail = error => ({
  type: CREATE_WORK_ORDER_FAIL,
  payload: error,
})

export const updateWorkOrder = (
  workorder,
  workorderId,
  history
) => ({
  type: UPDATE_WORK_ORDER,
  payload: { workorder, workorderId, history },
})

export const updateWorkOrderSuccess = workorder => ({
  type: UPDATE_WORK_ORDER_SUCCESS,
  payload: workorder,
})

export const updateWorkOrderFail = error => ({
  type: UPDATE_WORK_ORDER_FAIL,
  payload: error,
})

export const deleteWorkOrder = (workorderId, history) => ({
  type: DELETE_WORK_ORDER,
  payload: { workorderId, history },
})

export const deleteWorkOrderSuccess = workorder => ({
  type: DELETE_WORK_ORDER_SUCCESS,
  payload: workorder,
})

export const deleteWorkOrderFail = error => ({
  type: DELETE_WORK_ORDER_FAIL,
  payload: error,
})

export const getWorkOrderChoices =  (searchText, page ) => ({
  type: GET_WORK_ORDER_CHOICES,
  payload: { searchText, page },
})

export const getWorkOrderChoicesSuccess = workorderChoices => ({
  type: GET_WORK_ORDER_CHOICES_SUCCESS,
  payload: workorderChoices,
})

export const getWorkOrderChoicesFail = error => ({
  type: GET_WORK_ORDER_CHOICES_FAIL,
  payload: error,
})