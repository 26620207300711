import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"
import PropTypes from "prop-types"
import ProductionItemsTableViewCard from "pages/Production/ProductionItemsCrud/ProductionItemsTableViewCard"
// import ProductionItemWorksViewCard from "../ProductionItemWorksCrud/ProductionItemWorksViewCard"


const WorkOrderItemsTableViewCard = ({ items }) => {

  const download = () => {
    window.print()
  }
  return (
    <>
      <div className="" id="res-tab">
        {items?.map((value, index) => (
          <div className="work-order-item-table-parent" key={index}>
          <Table 
            id="myTable"
            className="work-order-item-table table table-bordered mb-0 text-center"
          >
            <thead>
              <tr>
                <th id="0">#</th>
                <th id="1">Sr. No</th>
                <th id="3">Product Type</th>
                <th id="4">Channel Size</th>
                <th id="5">Channel Gauge</th>
                <th id="6">Height</th>
                <th id="7">Width</th>
                <th id="8">Particulars</th>
                <th id="9">Grill Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{index + 1}</td>
                <td>{value.sr_no}</td>
                <td>{value.product_type}</td>
                <td>{value.channel_size}</td>
                <td>{value.channel_guage}</td>
                <td>{value.height}</td>
                <td>{value.width}</td>
                <td>{value.particulars}</td>
                <td>{value.grill_type}</td>
              </tr>
            </tbody>
          </Table>

          <Table 
            id="myTable"
            className="work-order-item-table table table-bordered mb-0 text-center"
          >
            <thead>
              <tr>
                <th id="10">Manchary</th>
              <th id="11">Louvers</th>
              <th id="12">Panel</th>
              <th id="13">Design</th>
              <th id="14">Open Side</th>
              <th id="15">Door Sill</th>
              <th id="16">Quantity</th>
              <th id="17">Hinges</th>
              <th id="18">Stopper</th>
              <th id="19">Gate Hook</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{value.manchary}</td>
                <td>{value.louvers}</td>
                <td>{value.panel}</td>
                <td>{value.design}</td>
                <td>{value.open_side}</td>
                <td>{value.doorsill}</td>
                <td>{value.quantity}</td>
                <td>{value.hinges}</td>
                <td>{value.stopper}</td>
                <td>{value.gate_hook}</td>
              </tr>
              <tr>
                <td colSpan={19}>
                  <ProductionItemsTableViewCard items={value?.production_items} />
                </td>
              </tr>
            </tbody>
          </Table>

          </div>
        ))}
      </div>
    </>
  )
}
WorkOrderItemsTableViewCard.propTypes = {
  items: PropTypes.array,
}
export default WorkOrderItemsTableViewCard
