import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import { map } from "lodash"
import PropTypes from "prop-types"
import ProductionItemWorksViewCard from "../ProductionItemWorksCrud/ProductionItemWorksTableViewCard"
import ProductionItemWorksTableViewCard from "../ProductionItemWorksCrud/ProductionItemWorksTableViewCard"


const ProductionItemsTableViewCard = ({ items }) => {

  console.log("items ////");
  console.log(items);

  const download = () => {
    window.print()
  }
  return (
    <>
      <div className="d-flex" id="res-tab">
        <Table
          id="myTable"
          className="production-item-table table table-bordered mb-0 text-center"
        >
          <thead>
            <tr>
              <th id="1">#</th>
              <th id="2">Sr/No</th>
              <th id="3">Current work</th>
              <th id="4">Current work status</th>
            </tr>
          </thead>
          {items?.map((value, index) => (
            <tbody key={index}>
              <tr>
                <td id="1">
                  {index + 1}
                </td>
                <td id="2">
                  {value?.sr_no}
                </td>
                <td id="3">
                  {value?.current_work}
                </td>
                <td id="4">
                  {value?.current_work_status}
                </td>
              </tr>
              {value?.production_item_works?.length ? (
                <tr>
                  <td colSpan={4}>
                    <ProductionItemWorksTableViewCard items={value?.production_item_works} />
                  </td>
                </tr>
              ):("")}
            </tbody>
          ))}

        </Table>
      </div>
    </>
  )
}
ProductionItemsTableViewCard.propTypes = {
  items: PropTypes.array,
}
export default ProductionItemsTableViewCard
