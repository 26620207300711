


import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"

import {  useParams } from "react-router-dom"
import { getWorkOrderDetail } from "store/actions"
// import Select from "react-select"
import WorkOrderItemsTableViewCard from "./WorkOrderItemsTableViewCard"
import WorkOrderDetailsViewCard from "./WorkOrderDetailsViewCard"
import WorkOrderImagesTableViewCard from "./WorkOrderImagesTableViewCard"
const SingleView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  console.log(params.id);

  const { WorkOrderDetail, loading } = useSelector(
    state => ({
      WorkOrderDetail: state.WorkOrders.workOrderDetail,
      loading: state.WorkOrders.loading,
    })
  )

  useEffect(() => {
    dispatch(getWorkOrderDetail(params.id))
    console.log("WorkOrderDetail");
    console.log(WorkOrderDetail);

  }, [params.id, dispatch])


  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className="page-content">
          <WorkOrderDetailsViewCard details={WorkOrderDetail??WorkOrderDetail} />
          <WorkOrderItemsTableViewCard items={WorkOrderDetail?.work_order_items} />
          <WorkOrderImagesTableViewCard items={WorkOrderDetail?.work_order_images} />
        
        
        </div>
      )}
    </>
  )
}
export default SingleView
